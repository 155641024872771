import { useEffect, useRef } from 'react';

/**
 * It runs a function only after the first render
 * @param func - The function to run on update
 * @param dep - An array of dependencies.
 */
const useDidUpdateEffect = (func, dep) => {
	const ref = useRef(false);
	useEffect(() => {
		if(ref.current) func();
		else ref.current = true;
	}, dep);
};
export default useDidUpdateEffect;

