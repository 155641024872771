import React, { useMemo } from 'react';
import { Select } from 'antd';
import useTranslation from 'hooks/useTranslation';
import { dataToSelectConvertor, enumToDropDown } from 'core/helpers/dropdownDataConverter';

const { Option } = Select;

/**
 * It takes an array of objects and returns an array of objects with the same keys but different values
 * @returns A React component
 */

const ModeSelect = ({
						onChange,
						placeholder,
						data = [],
						width = 250,
						valueKey = 'id',
						labelKey = 'name',
						dataIsEnum = false
					}) => {
	const translate = useTranslation();

	const optionData = useMemo(() => {
		let result = [];
		if(dataIsEnum) {
			result = enumToDropDown(data);
		} else {
			result = dataToSelectConvertor(data, { labelKey, valueKey });
		}

		return result;
	}, [ data ]);

	return (
		<Select
			mode="tags"
			onChange={onChange}
			className="ant_des_select"
			placeholder={translate(placeholder)}
			style={{
				width
			}}
		>
			{
				optionData.map(({ name, id }, index) => {
					return (
						<Option key={name} id={id}>
							{name}
						</Option>
					);
				})
			}
		</Select>
	);
};

export default ModeSelect;