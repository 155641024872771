import Fetch from '../fetch';
import { CONTENT_TYPES } from '../../../constants/apiConstants/contentTypes';

const END_POINTS = {
	prefix: 'ChatChannels',
	groups: 'groups',
	state: 'state',
	messages: 'messages',
	recipients: 'recipients',
	attachments: 'attachments'
};

class ChatChannelsApiHandler {
	async createChannelsGroups(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.groups}`, data);
	}

	async createChannels(data) {
		return Fetch.post(END_POINTS.prefix, data);
	}

	async deleteChatMessage({ chatId, messageId }) {
		return Fetch.delete(`${END_POINTS.prefix}/${chatId}/${END_POINTS.messages}/${messageId}`);
	};

	async getChannelsGroups(filter) {
		return Fetch.get(END_POINTS.prefix, filter);
	}

	async updateChatGroupsUsers({ id, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.groups}/${id}/${END_POINTS.recipients}`, data);
	}

	async getChatChannelsRecipients(id) {
		return Fetch.get(`${END_POINTS.prefix}/${id}/${END_POINTS.recipients}`);
	}

	async changeMemberState({ id, state, memberId }) {
		return Fetch.patch(`${END_POINTS.prefix}/${id}/${END_POINTS.recipients}/${END_POINTS.state}/${state}?memberId=${memberId}`);
	}

	async memberLeaveGroupChat({ id, state }) {
		return Fetch.patch(`${END_POINTS.prefix}/${id}/${END_POINTS.recipients}/${END_POINTS.state}/${state}`);
	}

	async senMessage({ id, formData }) {
		return Fetch.post(`${END_POINTS.prefix}/${id}/${END_POINTS.messages}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async changeChatState({ id, state }) {
		return Fetch.patch(`${END_POINTS.prefix}/${id}/${END_POINTS.state}/${state}`);
	}

	async getMessageAttachment(id) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.messages}/${END_POINTS.attachments}/${id}`);
	}

	async getMessages({ id, ...filter }) {
		return Fetch.get(`${END_POINTS.prefix}/${id}/${END_POINTS.messages}`, filter);
	}
}

export default new ChatChannelsApiHandler();