import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useDidUpdateEffect from './useDidUpdateEffect';


/**
 * It returns a debounced value, which is a value that is delayed by a specified amount of time
 * @param value - The value to be debounced.
 * @param delay - The delay in milliseconds before the debounced value is updated.
 * @param clearTimeoutAfterPathChange - If true, the timeout will be cleared when the path changes.
 * @returns A debounced value.
 */
export const useDebounce = (value, delay, clearTimeoutAfterPathChange) => {
    // State and setters for debounced value

    const [debouncedValue, setDebouncedValue] = useState(value);
    const timeoutId  = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
        timeoutId.current = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            timeoutId.current && clearTimeout(timeoutId.current);
        }
    }, [value, delay]);
    useDidUpdateEffect(() => {
        clearTimeoutAfterPathChange && timeoutId.current && clearTimeout(timeoutId.current);
    }, [pathname]);
    return debouncedValue;
};