import React, { useEffect, useState } from 'react';
import ShowElement from '../showElement';
import { uuId } from 'core/helpers/uuidv4';
import { IMG_URL } from 'core/constants/urls';
import useTranslation from 'hooks/useTranslation';
import { message, Typography, Upload } from 'antd';
import { getBase64 } from 'core/helpers/getBase64';
import T from 'core/translations/translations.json';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss';

const { Text } = Typography;

/**
 * It renders an upload button that allows the user to upload an image
 * @returns A React component
 */

const ImgUpload = ({
					   showUploadAction = true,
					   onSetFileData,
					   imagePath,
					   imgWarningText,
					   errorMessage

				   }) => {
	const [ fileList, setFileList ] = useState([]);

	const [ loading, setLoading ] = useState(false);
	const [ imageUrl, setImageUrl ] = useState('');

	const translate = useTranslation();

	useEffect(() => {
		if(imagePath) {
			setImageUrl(`${IMG_URL}/${imagePath}`);
		} else {
			setFileList([]);
			setImageUrl('');
		}

	}, [ imagePath ]);

	useEffect(() => {
		imagePath && handleSetFileData();
	}, [ imagePath ]);

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined/> : <PlusOutlined/>}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const handleSetFileData = () => {
		setFileList([
			{
				uid: uuId(),
				name: '',
				url: `${IMG_URL}/${imagePath}`,
				status: 'done'
			}
		]);
	};
	const handleChange = (info) => {
		const { file } = info;

		if(file.status === 'removed') {
			setFileList([]);
			return null;
		}

		file.originFileObj && getBase64(file.originFileObj, imageUrl => {
				const img = new Image();
				img.src = imageUrl;
				img.onload = function () {
					if(file.status === 'uploading') {
						setLoading(true);

						setTimeout(() => {
							setLoading(false);
							onSetFileData({
								blob: info.file.originFileObj,
								name: info.file.name
							});
						}, 500);
					}

					setTimeout(() => {
						setFileList([
							{
								uid: file.originFileObj.uid,
								name: file.name,
								url: imageUrl,
								status: 'done'
							}
						]);
						!showUploadAction && setImageUrl(imageUrl);
						setLoading(false);
					}, 500);
				};
			}
		);
	};

	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

		const isLt2M = file.size / 1024 / 1024 < 2;
		if(!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}

		if(!isJpgOrPng) {
			message.error(translate(T.IMG_TYPE_WARNING));
		}
		return isJpgOrPng && isLt2M;
	};


	return (
		<>
			<div className="upload-img-content">
				<Upload
					accept="image/*"
					name="avatar"
					listType="picture-card"
					maxCount={1}
					fileList={fileList}
					className="avatar-uploader"
					showUploadList={showUploadAction}
					onChange={handleChange}
					beforeUpload={beforeUpload}
				>
					{showUploadAction ? !fileList.length && uploadButton : (
						imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }}/> : uploadButton
					)}
				</Upload>

				<ShowElement isShow={imgWarningText}>
					<Text type="secondary" className="text-content">
						{translate(imgWarningText)}
					</Text>
				</ShowElement>
			</div>

			<div>
				<ShowElement isShow={errorMessage}>
					<Text type="danger">{errorMessage}</Text>
				</ShowElement>
			</div>
		</>
	);
};

export default ImgUpload;