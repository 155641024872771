import React from 'react';
import { observer } from 'mobx-react-lite';
import useTranslation from 'hooks/useTranslation';
import languagesStore from '../../../../stateManagement/stores/languagesStore';

const Description = ({ description }) => {
	const { languagesForProject: { data } } = languagesStore;

	const translate = useTranslation(data);

	return (
		<span>{translate(description)}</span>
	);
};

export default observer(Description);