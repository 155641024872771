import { Avatar, Badge, Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { BellOutlined } from '@ant-design/icons';
import OverlayContent from './components/overlayContent';
import chatChannelsStore from 'stateManagement/stores/chatChannelsStore';
import './index.scss';

const ChatDropDown = () => {
	const { chatEvents: { data: { count, ...chatEventsData } } } = chatChannelsStore;

	const handleResetChatEventsList = (event) => {
		if(!event) {
			chatChannelsStore.clearSignalrEventData();
		}
	};

	return (
		<Dropdown
			onOpenChange={handleResetChatEventsList}
			trigger={[ 'click' ]}
			overlay={
				<OverlayContent
					chatEvents={chatEventsData}
				/>
			}
			placement="bottomRight"
		>
			<Badge count={count}>
				<Avatar className="chat_bell_outlined">
					<BellOutlined/>
				</Avatar>
			</Badge>
		</Dropdown>
	);
};

export default observer(ChatDropDown);