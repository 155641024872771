import React from 'react';
import PropTypes from 'prop-types';
import { Button as CntdBytton } from 'antd';
import useTranslation from 'hooks/useTranslation';
import './index.scss';

/**
 * It's a function that returns a button component that takes in a bunch of props and returns a button with the props
 * passed in
 * @returns A function that returns a component.
 */

const Button = ({
					style,
					type = "default",
					text = '',
					title = '',
					size = "medium",
					childrenClassName,
					children,
					...restProps
				}) => {
	const translate = useTranslation();
	return (
		<CntdBytton
			type={type}
			size={size}
			style={{ ...style }}
			className="button-wrapper"
			{...restProps}
		>
           <span className="childrenClassName">
                {children || translate(text)}
           </span>
		</CntdBytton>
	);
};

Button.propTypes = {
	style: PropTypes.object,
	type: PropTypes.oneOf([ 'primary', 'ghost', 'dashed', 'link', 'text', 'default' ]),
	title: PropTypes.string,
	size: PropTypes.oneOf([ 'medium', 'large' ]),
	text: PropTypes.string
};

export default Button;