import React from 'react';
import { Spin } from 'antd';
import './index.scss'

/**
 * It takes a boolean prop called loading and a children prop and returns a div with a loading spinner if loading is true
 * and the children prop if loading is false
 * @returns A React component that renders a loading spinner if the loading prop is true, and the children prop if the
 * loading prop is false.
 */

const LoadingWrapper = ({ loading, children }) => {
	return (
		<div className="loading_wrapper">
			<Spin tip="loading.."  spinning={loading}>
				{children}
			</Spin>
		</div>
	);
};

export default LoadingWrapper;