import React, { useMemo } from 'react';
import { Select } from 'antd';
import useTranslation from 'hooks/useTranslation';
import { dataToSelectConvertor, enumToDropDown } from 'core/helpers/dropdownDataConverter';

const { Option } = Select;

/**
 * It's a wrapper around the antd Select component that allows you to pass in an array of objects and it will convert it to
 * the format that antd expects
 * @returns A Select component with a few props.
 */

const MultipleSelect = ({
							name,
							label,
							values,
							loading,
							onChange,
							placeholder,
							data = [],
							width = 250,
							valueKey = 'id',
							labelKey = 'name',
							allowClear = false,
							dataIsEnum = false,
							optionFilterProp = 'children',
							...restProps
						}) => {
	const translate = useTranslation();

	const optionData = useMemo(() => {
		let result = [];

		if(dataIsEnum) {
			result = enumToDropDown(data);
		} else {
			result = dataToSelectConvertor(data, { labelKey, valueKey });
		}

		return result;
	}, [ data ]);

	return (
		<Select
			name={name}
			showSearch
			mode="multiple"
			loading={loading}
			style={{ width }}
			allowClear={allowClear}
			className="ant_des_select"
			optionFilterProp={optionFilterProp}
			placeholder={translate(placeholder)}
			onChange={(valueType) => onChange([ ...valueType ])}
			{...restProps}
		>
			{
				optionData.map((item) => (
					<React.Fragment key={item.value}>
						<Option key={item.value}>{item.label}</Option>
					</React.Fragment>
				))
			}
		</Select>
	);
};

export default MultipleSelect;