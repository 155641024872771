import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { linkTypes } from './utils';
import { fontWightTypes } from '../utils';
import { Link as RouteLink } from 'react-router-dom';
import { customGenericClassNames } from '../helpers';
import './index.scss';

const { Link: TypographyLink } = Typography;

/**
 * It's a function that returns a component that renders a link
 * @returns A function that takes in props and returns a component.
 */
const Link = ({
				  to,
				  size = linkTypes.small,
				  className = '',
				  ellipsis = true,
				  fontWeight = fontWightTypes.regular,
				  text,
				  onClick,
				  children,
				  type,
				  ...restProps
			  }) => {
	const hrefClassName = `${className} ${customGenericClassNames(
		type,
		ellipsis
	)} linkWrapper ${size} ${fontWeight} link`;

	if(to) {
		return (
			<RouteLink to={to} className={`${hrefClassName}`} {...restProps}>
				{text || children}
			</RouteLink>
		);
	}

	// this component use only for style
	return (
		<TypographyLink onClick={onClick} className={hrefClassName} {...restProps}>
			{text || children}
		</TypographyLink>
	);
};

Link.propTypes = {
	to: PropTypes.string,
	size: PropTypes.oneOf([ 'small', 'base' ]),
	className: PropTypes.string,
	ellipsis: PropTypes.bool,
	fontWeight: PropTypes.oneOf([ 'bold', 'medium', 'regular' ]),
	text: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.element
};
export default Link;