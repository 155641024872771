import React from 'react';
import { Upload } from 'antd';
import useTranslation from 'hooks/useTranslation';
import { UploadOutlined } from '@ant-design/icons';
import T from 'core/translations/translations.json';
import { Button, ShowElement, Typography } from 'view/components/shared';

const { BaseHeading } = Typography;

const UploadFile = ({ title, accept, setFileData, onChange }) => {
	const translate = useTranslation();

	const handleChange = (info) => {
		if(!info.fileList.length) {
			onChange && onChange(null);
			return null;
		}

		if(info.file.status === 'uploading') {
			return null;
		}

		setFileData && setFileData({
			blob: info.file,
			name: info.file.name
		});

		console.log(info.file, 'info.file');
		onChange && onChange({
			blob: info.file,
			name: info.file.name
		});
	};

	const beforeUpload = () => false;
	return (
		<div>
			<ShowElement isShow={title}>
				<BaseHeading
					level={4}
					fontWeight="regular"
				>
					{title}
				</BaseHeading>
			</ShowElement>
			<Upload
				name="file"
				maxCount={1}
				accept={accept}
				howUploadList={false}
				onChange={handleChange}
				beforeUpload={beforeUpload}
			>
				<Button icon={<UploadOutlined/>}>{translate(T.CLICK_TO_UPLOAD)}</Button>
			</Upload>
		</div>
	);
};

export default UploadFile;