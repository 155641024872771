import React from 'react';
import Routes from '../routes';
import { adminRouters } from 'routing/router';
import { observer } from 'mobx-react-lite';

const RouterOutlet = () => {
	return (
		<div>
			<Routes routes={adminRouters}/>
		</div>

	);
};

export default observer(RouterOutlet);
