/**
 * It takes an enum and returns an array of objects that can be used in a dropdown
 */
export const enumToDropDown = dataEnum => dataEnum.keys.map(item => ({
	id: item,
	label: dataEnum[item],
	value: item
}));

/**
 * It takes an array of objects and returns an array of objects with the keys `key`, `label`, and `value`
 * @param data - The data that you want to convert to select options.
 * @param option - {
 */
export const dataToSelectConvertor = (data, option) => data.map((item) => ({
	key: item[option.valueKey].toString(),
	label: item[option.labelKey],
	value: item[option.valueKey].toString()
}));