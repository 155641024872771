import React from 'react';
import { Typography } from 'antd';
import { customGenericClassNames } from '../helpers';
import { fontWightTypes, headingTypes } from '../utils';
import './index.scss';

const { Title } = Typography;

/**
 * It's a function that returns a component that renders a heading
 * @returns A React component
 */
const Heading = ({
					 text,
					 type,
					 className = '',
					 ellipsis = true,
					 hideMargin = false,
					 level = headingTypes.h1,
					 fontWeight = fontWightTypes.bold,
					 children,
					 ...restProps
				 }) => {
	return (
		<Title
			level={level}
			className={`${className} ${customGenericClassNames(type, ellipsis)} heading_wrapper ${headingTypes[level]} ${fontWeight} ${
				hideMargin ? 'hide_margin' : ''
			}`}
			{...restProps}
		>
			{text || children}
		</Title>
	);
};

export default Heading;