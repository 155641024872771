import ApiHandler from '../apiHandler';
import Fetch from '../fetch';
import { CONTENT_TYPES } from 'core/constants/apiConstants/contentTypes';

const END_POINTS = {
	me: 'me',
	prefix: 'Users',
	profile: 'profile',
	resume: 'resume',
	activate: 'activate',
	instructorProfile: 'instructorProfile'
};

class UsersApiHandler extends ApiHandler {
	async setActivateKey(activationKey) {
		return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.activate}/${activationKey}`);
	}

	async getProfile() {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.profile}`);
	}

	async getMe() {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.me}`);
	}

	async addInstructorProfile(formData) {
		return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async getInstructorProfileResume(data = {}) {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}/${END_POINTS.resume}`, data, { contentType: CONTENT_TYPES.OCTET_STREAM });
	}

	async getUserProfile() {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.profile}`);
	}

	async editInstructorProfile(formData) {
		return await Fetch.put(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}
}

const usersApiHandler = new UsersApiHandler();
export default usersApiHandler;