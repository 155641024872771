import { useContext } from 'react';
import { TranslationContext } from '../context';


/**
 * It returns a function that takes a key and returns the translation for that key if it exists, otherwise it returns the
 * key
 * @returns A function that takes a key and returns the translation for that key.
 */
const useTranslation = (helpersData) => {
	const contextData = useContext(TranslationContext);
	const translations = helpersData ? helpersData : contextData;
	return key => Object.keys(translations).length && translations[key] ? translations[key] : key;
};

export default useTranslation;