const fontWightTypes = {
	bold: 'bold',
	medium: 'medium',
	regular: 'regular'
};

const headingTypes = {
	h1: 1,
	h2: 2,
	h3: 3,
	h4: 4
};

export { fontWightTypes, headingTypes };