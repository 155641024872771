import React, { useMemo } from 'react';
import { Select as AntdSelect } from 'antd';
import useTranslation from 'hooks/useTranslation';
import { dataToSelectConvertor, enumToDropDown } from 'core/helpers/dropdownDataConverter';
import './index.scss';

const { Option } = AntdSelect;

/**
 * It takes in a bunch of props, and returns a Select component
 * @returns A React component
 */
const Select = ({
					value,
					loading,
					onChange,
					children,
					className = '',
					placeholder,
					data = [],
					width = 250,
					valueKey = 'id',
					labelKey = 'name',
					readOnly = false,
					allowClear = false,
					dataIsEnum = false,
					showSearch = true,
					optionFilterProp = 'children',

					...restProps
				}) => {
	const translate = useTranslation();

	const optionData = useMemo(() => {
		let result = [];
		if(dataIsEnum) {
			result = enumToDropDown(data);
		} else {
			result = dataToSelectConvertor(data, { labelKey, valueKey });
		}

		return result;
	}, [ data ]);

	const selectedValue = useMemo(() => {
		if(optionData.length) {
			return value || value === 0 ? `${value}` : value;
		} else {
			return '';
		}
	}, [ optionData, value ]);

	return (
		<AntdSelect
			loading={loading}
			style={{ width }}
			readOnly={readOnly}
			onChange={onChange}
			showSearch={showSearch}
			allowClear={allowClear}
			optionFilterProp={optionFilterProp}
			placeholder={translate(placeholder)}
			value={selectedValue}
			className={`ant_des_select ${className} ${readOnly ? 'readonly_select' : ''}`}
			{...restProps}
		>
			{
				children ? children : (
					optionData.map((item) => {
						return (
							<React.Fragment key={item.value}>
								<Option value={item.value}>{translate(item.label)}</Option>
							</React.Fragment>
						);
					})
				)
			}
		</AntdSelect>
	);
};

Select.Option = (value, children) => {
	return (
		<Option value={value}>{children}</Option>
	);
};

export default Select;