import React, { useState } from 'react';
import { Input } from 'antd';
import useTranslation from 'hooks/useTranslation';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import './index.scss';

/**
 * It's a wrapper around the Ant Design TextArea component that allows for controlled value
 * @returns A TextArea component that is a wrapper around the Ant Design TextArea component.
 */
const TextArea = ({
					  name,
					  autoSize,
					  readOnly,
					  onChange,
					  placeholder,
					  value: controlledValue,
					  ...restProps
				  }) => {
	const [ value, setValue ] = useState(controlledValue);
	const translate = useTranslation();

	useDidUpdateEffect(() => {
		setValue(controlledValue);
	}, [ controlledValue ]);

	const handleChange = (event) => {
		let { value = '' } = event.target;
		//replace(/^\s+/g, '') this function removed before space :))
		value = value?.replace(/^\s+/g, '');
		setValue(value);
		event.target.value = value;
		onChange && onChange(event);
	};

	return (
		<Input.TextArea
			rows={7}
			name={name}
			readOnly={readOnly}
			autoSize={autoSize}
			value={value ?? ''}
			onChange={handleChange}
			placeholder={translate(placeholder)}
			className={`main_text_area ${readOnly ? 'readonly_text_area' : ''}`}
			{...restProps}
		/>
	);
};

export default TextArea;