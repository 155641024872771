import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';

const BaseEmailEditor = ({ raf }) => {
	const emailEditorRef = useRef(null);


	return (
		<div>
			<EmailEditor style={{ height: '70vh' }} ref={emailEditorRef}/>
		</div>
	);
};

export default BaseEmailEditor;