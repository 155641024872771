import { Avatar as AntAvatar } from 'antd';
import { IMG_URL } from 'core/constants/urls';
import altUserImg from './altUser.png';
import { UserOutlined } from '@ant-design/icons';

const Avatar = ({ src, size = 24 }) => {
	return (
		<AntAvatar
			size={size}
			src={src ? `${IMG_URL}/${src}` : altUserImg}
		>
			<UserOutlined/>
		</AntAvatar>
	);
};

export default Avatar;