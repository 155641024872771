import { observer } from 'mobx-react-lite';
import userStore from 'stateManagement/stores/userStore';

/**
 * If the user's type is the same as the userType prop, then return the children
 * @returns The children of the Permission component if the userType matches the type of the user.
 */

const Permission = ({ userType, children }) => {
	const { meData: { data: { type } } } = userStore;
	if(type === userType) {
		return children;
	}

	return null;
};

export default observer(Permission);