import React from 'react';
import { Switch } from 'antd';
import FormItem from '../formItem';
import useTranslation from 'hooks/useTranslation';

/**
 * It's a wrapper around the `<Switch />` component from `antd` that adds a label and a `<FormItem />` wrapper
 * @returns A React component that renders a form item with a switch.
 */
const Switcher = ({ label, name, disabled = false, onChange, valuePropName = 'checked' }) => {
	const translate = useTranslation();

	return (
		<FormItem
			name={name}
			label={translate(label)}
			valuePropName={valuePropName}
		>
			<Switch
				name={name}
				disabled={disabled}
				onChange={onChange}
			/>
		</FormItem>
	);
};

export default Switcher;