import { Layout } from 'antd';
import Logo from '../../../shared/logo';
import { ROUTE_CONSTANTS } from 'routing/router';
import { LanguageSelect } from 'view/components/shared';
import devaBlackLogo from 'assets/icons/devaBlackLogo.svg';

const { Header } = Layout;

const MainHeader = ({ languageId, onChangeLanguage, languagesList }) => {
	return (
		<Header className="site-header-layout space_between">
			<div className="header-logo">
				<Logo href={ROUTE_CONSTANTS.EMPTY} src={devaBlackLogo}/>
			</div>

			<LanguageSelect
				noStyle
				width={150}
				value={languageId}
				data={languagesList}
				onChange={onChangeLanguage}
			/>
		</Header>
	);
};

export default MainHeader;