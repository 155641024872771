import React from 'react';
import { observer } from 'mobx-react-lite';
import { ROUTE_CONSTANTS } from '../router';
import { Redirect } from 'react-router-dom';
import authStore from 'stateManagement/stores/authStore';

/**
 * If the user is authenticated, redirect them to the translations page, otherwise, render the children
 * @returns The children of the IsNotAuthLayer component.
 */
const IsNotAuthLayer = ({ children }) => {
	const { isAuth } = authStore;
	return <>{ isAuth ? <Redirect to={ROUTE_CONSTANTS.DASHBOARD}/> : children}</>;
};

export default observer(IsNotAuthLayer);

