import React from 'react';

const Header = ({ header: { leftContent, rightContent } }) => {
	return (
		<div className="header">
			<div className="left_fr">
				{leftContent}
			</div>

			<div className="right_fr">
				{rightContent}
			</div>
		</div>
	);
};

export default Header;