import React, { useCallback } from 'react';
import useTranslation from 'hooks/useTranslation';
import { Dropdown as AntDropDown, Menu, Space } from 'antd';
import './index.scss';

/**
 * It takes in an array of objects, and returns a dropdown menu with the items in the array
 * @returns A dropdown menu
 */

const DropDown = ({
					  data,
					  arrow = true,
					  trigger = 'click',
					  children,
					  position = "bottomRight",
					  onDropDownChange,
					  ...restProps
				  }) => {
	const translate = useTranslation();

	const menu = useCallback(() => {
		return (
			<Menu>
				{
					data.map((item) => (
						<Menu.Item
							key={item.key}
							onClick={() => {
								if(item.onClick && !onDropDownChange) {
									item.onClick();
								}

								if(onDropDownChange) {
									onDropDownChange(item);
								}
							}}
						>
							<Space>
								{item.icon}
								<span>{translate(item.title)}</span>
							</Space>
						</Menu.Item>
					))
				}
			</Menu>
		);
	}, [ data ]);

	return (
		<AntDropDown
			arrow={arrow}
			overlay={menu}
			placement={position}
			trigger={[ trigger ]}
			overlayClassName="dropdown-wrapper"
			{...restProps}
		>
			{children}
		</AntDropDown>
	);
};

export default DropDown;