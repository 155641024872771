import React from 'react';
import { Modal as AntdModal } from 'antd';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import './index.scss';

/**
 * It's a wrapper around the Ant Design Modal component that adds some default props and styling
 * @returns A modal component
 */

const Modal = ({
				   title,
				   onSave,
				   footer,
				   visible,
				   onCancel,
				   children,
				   okButtonLoading,
				   okButtonDisabled,
				   width = 600,
				   okText = T.SAVE,
				   cancelText = T.CANCEL,
				   centered = true,
				   ...restProps
			   }) => {
	const translate = useTranslation();

	return (
		<AntdModal
			onOk={onSave}
			width={width}
			footer={footer}
			open={visible}
			centered={centered}
			onCancel={onCancel}
			maskClosable={false}
			cancelText={translate(cancelText)}
			okText={translate(okText)}
			title={translate(title)}
			className="ant_des_modal"
			cancelButtonProps={{
				className: 'ant_des_modal_cancel_button'
			}}
			okButtonProps={{
				disabled: okButtonDisabled,
				loading: okButtonLoading,
				className: 'ant_des_modal_save_button'
			}}
			{...restProps}
		>

			{children}
		</AntdModal>
	);
};

export default Modal;