/* A constant that is an object with key value pairs. */
export const STATUS_ERROR_CODES = {
	400: 'BAD_REQUEST',
	401: 'UNAUTHORIZED',
	403: 'FORBIDDEN',
	404: 'NOT_FOUND',
	406: 'NOT_ACCEPTABLE',
	500: 'SERVER_ERROR',
	502: 'BAD_GATEWAY',
	503: 'SERVICE_UNAVAILABLE',
	504: 'GATEWAY_TIMEOUT',
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403
	//Pending.....
};

