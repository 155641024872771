import { Layout } from 'antd';
import UserProfile from '../userProfile';
import ChatDropDown from './chatDropDown';
import { observer } from 'mobx-react-lite';
import { LanguageSelect } from 'view/components/shared';
import userStore from 'stateManagement/stores/userStore';

const { Header } = Layout;


const CabinetHeader = ({ languageId, onChangeLanguage, languagesList }) => {
	const { meData: { data: { firstName, imagePath, email, id } } } = userStore;

	return (
		<Header className="site-header-layout">
			<div className="header-main-content">
				<div id="header-title-container" className="page-title-content"/>

				<div className="right-bar">
					<ul>
						<li>
							<ChatDropDown/>
						</li>

						<li>
							<LanguageSelect
								noStyle
								width={150}
								value={languageId}
								data={languagesList}
								onChange={onChangeLanguage}
							/>
						</li>

						<li className="user-profile-container">
							<UserProfile
								id={id}
								email={email}
								name={firstName}
								imagePath={imagePath}
							/>
						</li>
					</ul>
				</div>
			</div>
		</Header>
	);
};

export default observer(CabinetHeader);