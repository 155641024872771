import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import './index.scss';

const TimePicker = ({ width = '200', className, ...restProps }) => {
	return (
		<AntTimePicker
			style={{ width }}
			className={`ant_time_picker ${className}`}
			{...restProps}
		/>
	);
};

export default TimePicker;