export const downloadStreamFile = (resp, fileName) => {
	resp.blob && resp.blob()
		.then(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.setAttribute('download', fileName);
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
};