import React from 'react';
import { Drawer } from 'antd';
import Header from './header';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss';

const TableOverview = ({
						   width = 750,
						   visible,
						   children,
						   components,
						   onCloseOverview
					   }) => {
		return (
			<Drawer
				mask
				keyboard
				closeIcon={
					<div className="icon_content">
						<CloseOutlined onClick={onCloseOverview}/>
					</div>
				}
				width={width}
				destroyOnClose
				placement="right"
				visible={visible}
				getContainer={false}
				onClose={onCloseOverview}
				className="overview_main_container"
				title={
					<Header
						onClose={onCloseOverview}
						header={components.header}
					/>
				}
			>
				{children}
			</Drawer>
		);
	}
;

export default TableOverview;