import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Avatar, Dropdown, Menu } from 'antd';
import { IMG_URL } from 'core/constants/urls';
import { ROUTE_CONSTANTS } from 'routing/router';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { Typography } from 'view/components/shared';
import authStore from 'stateManagement/stores/authStore';

const { BaseBodyText } = Typography;

const UserProfile = ({ id, name, imagePath, email }) => {
	const translate = useTranslation();
	const history = useHistory();

	const handleSignOut = () => {
		authStore.signOut();
	};

	const redirectMyCabinet = () => {
		history.push(`${ROUTE_CONSTANTS.USER_MANAGEMENT}${ROUTE_CONSTANTS.EDIT_USER}/${id}`);
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={redirectMyCabinet}>
				<div>
					<BaseBodyText>{translate(T.ACCOUNT_SETTINGS)}</BaseBodyText>
				</div>

				<div>
					<BaseBodyText type="secondary">{email}</BaseBodyText>
				</div>
			</Menu.Item>

			<Menu.Item onClick={handleSignOut}>
				{translate(T.LOG_OUT)}
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu}
			className="profile-overlay-menu"
		>
			<div className="user_profile">
				<BaseBodyText size="small">
					{name}
				</BaseBodyText>
				<Avatar
					className="avatar"
					style={{
						backgroundColor: '#d1e5fd',
						color: '#40A9FF',
						borderRadius: '10px'
					}}
					src={
						imagePath ? <img src={`${IMG_URL}/${imagePath}`} width="100px" alt=""/> : null
					}
				>
					{name?.slice(0, 1)}
				</Avatar>
			</div>
		</Dropdown>

	);
};

export default observer(UserProfile);