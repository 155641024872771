import Fetch from '../apiService/fetch';
import { handleCheckIsChatPage } from './helpers';
import LocalStorageHelper from '../../helpers/localStorageHelper';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import chatChannelsStore from 'stateManagement/stores/chatChannelsStore';
import { signalrWithUrl, storageStateKey, hubConnectionTypes } from './constant';
import { chatChannelState } from '../../constants/enums';


class CreateHubConnection {
	constructor(url) {
		this.connection = new HubConnectionBuilder().withUrl(url, {
			accessTokenFactory(e) {
				return Fetch.token;
			}
		})
			.configureLogging(LogLevel.Error)
			.build();
	}

	/**
	 * "When a message is received, if the user is on the chat page, update the message list, otherwise update the signalr
	 * event."
	 *
	 * The first thing we do is check if the user is on the chat page. If they are, we update the message list. If they
	 * aren't, we update the signalr event
	 */
	connectMessageReceived() {
		this.connection.on(hubConnectionTypes.MESSAGE_RECEIVED, data => {
			const isChatPage = handleCheckIsChatPage();
			if(isChatPage) {
				chatChannelsStore.updateMessageList(data);
			}

			chatChannelsStore.updateSignalrEvent(data);
		});
	}

	/**
	 * This function listens for a `GROUP_CHAT_CREATED` event from the server and updates the chat groups in the store
	 */
	connectGroupChatCreated() {
		this.connection.on(hubConnectionTypes.GROUP_CHAT_CREATED, data => {
			const isChatPage = handleCheckIsChatPage();
			if(isChatPage) {
				chatChannelsStore.updateChatGroups(data);
			}
		});
	}

	/**
	 * If the user is on the chat page, and the message that was removed is in the current chat, then remove the message from
	 * the chat
	 */
	connectMessageRemoved() {
		this.connection.on(hubConnectionTypes.MESSAGE_REMOVED, data => {
			const isChatPage = handleCheckIsChatPage();
			if(isChatPage) {
				const currentChat = LocalStorageHelper.getItem(storageStateKey);
				if(data.chatChannelId === currentChat.id) {
					chatChannelsStore.removeMessageInChat(data.chatMessageId);
				}
			}
		});
	}

	/**
	 * It removes the channel from the chatChannelsStore if the user is on the chat page
	 */
	connectChatChannelStateUpdated() {
		this.connection.on(hubConnectionTypes.CHAT_CHANNEL_STATE_UPDATED, data => {
			const isChatPage = handleCheckIsChatPage();
			if(isChatPage) {
				if(data.state === chatChannelState.CLOSED) {
					chatChannelsStore.removeChannelForGroups(data);
				}

				// if(data.state === chatChannelState.OPEN) {
				// 	console.log(data, '>>>>');
				// 	// chatChannelsStore.updateChatGroups()
				// }
			}
		});
	}

	chatChannelRecipientStateUpdated() {
		this.connection.on(hubConnectionTypes.CHAT_CHANNEL_RECIPIENT_STATE_UPDATED, data => {
			if(data.state === chatChannelState.SUSPENDED) {
				chatChannelsStore.removeChatInGroups(data.chatChannelId);
			}
			if(data.state===1){
				var temp={id:data.chatChannelId,
					name:null,
					isGroup:false,
					state:1,
					creationDate:data.connectionDate,
					recipient:{id:data.Id,
							  imagePath:data.imagePath,
							  name:data.name}}
				chatChannelsStore.addNewGroup(temp);
			}
		});
	}

	connectedAllSignalrEvent() {
		this.connectMessageRemoved();
		this.connectMessageReceived();
		this.connectGroupChatCreated();
		this.connectChatChannelStateUpdated();
		this.chatChannelRecipientStateUpdated();
	}

}

export const hubConnection = new CreateHubConnection(signalrWithUrl);