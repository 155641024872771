import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDebounce } from 'hooks/useDebounce';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import './index.scss';

const { Search: SearchComponent } = Input;

/**
 * It's a search component that takes in a value, a setValue function, an onChange function, a placeholder, a debounceTime,
 * and a width. It returns a search component that has a value, a style, an onChange function, a placeholder, and a
 * className
 * @returns A Search component that takes in a value, setValue, onChange, placeholder, debounceTime, and width.
 */

const Search = ({
					value,
					setValue,
					onChange,
					placeholder,
					debounceTime,
					width = 250,
				}) => {
	const [ searchTerm, setSearchTerm ] = useState('');

	useEffect(() => {
		setSearchTerm(value || '');
	}, [ value ]);

	const debouncedSearchTerm = useDebounce(searchTerm, debounceTime, false);

	useDidUpdateEffect(() => {
		onChange(debouncedSearchTerm);
	}, [ debouncedSearchTerm ]);

	const handleChange = event => {
		const { value } = event.target;
		setValue(value);
	};

	return (
		<div className="ant_des_search_container">
			<SearchComponent
				value={value}
				style={{ width }}
				onChange={handleChange}
				placeholder={placeholder}
				className="ant_des_search"
			/>
		</div>

	);
};

export default Search;