import React, { useMemo } from 'react';
import { Form, Input, Typography } from 'antd';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { INPUT_VALIDATION } from 'core/constants/inputValidation';
import './index.scss';

const { Title } = Typography;

/**
 * It's a React component that renders a password input field
 * @returns A function that returns a Form.Item component.
 */
const PasswordInput = ({
						   label,
						   name = 'password',
						   rules = [],
						   placeholder,
						   requiredMessage,
						   dependencies,
						   tooltip,
						   isCheckValidPassword,
						   isIcon = true,
						   matchPassword
					   }) => {
	const translate = useTranslation();

	const inputProps = useMemo(() => {
		const propsData = {};
		if(!isIcon) {
			propsData.iconRender = () => null;
		}

		return propsData;
	}, [ isIcon ]);

	const rulesData = useMemo(() => {
		let data = [];

		if(rules?.length) {
			data = rules;
		}

		if(requiredMessage) {
			data.push({
				required: true,
				message: translate(requiredMessage)
			});
		}

		return data;
	}, [ rules ]);

	const labelTitle = useMemo(() => {
		const style = {};

		if(tooltip) {
			style.marginBottom = '0';
		}

		if(label) {
			return <Title level={5} style={style}>{translate(label)}</Title>;
		}

		return null;
	}, [ label ]);

	return (
		<Form.Item
			name={name}
			label={labelTitle}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			dependencies={dependencies}
			tooltip={translate(tooltip)}
			rules={[
				({ getFieldValue }) => ({
					validator(_, value) {
						if(!value) {
							return Promise.reject(translate(T.PLEASE_INPUT_YOUR_PASSWORD));
						}

						if(!INPUT_VALIDATION.PASSWORD.pattern.test(value) && isCheckValidPassword) {
							return Promise.reject(translate(INPUT_VALIDATION.PASSWORD.message));
						}

						if(matchPassword && value && getFieldValue(matchPassword) !== value) {
							return Promise.reject(new Error('The two passwords that you entered do not match!'));
						}

						return Promise.resolve();
					}
				}),
				...rulesData
			]}
			hasFeedback={true}
		>
			<Input.Password
				size="large"
				className="input-content"
				placeholder={translate(placeholder)}
				{...inputProps}
			/>
		</Form.Item>
	);
};

export default PasswordInput;