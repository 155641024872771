import ApiHandler from '../apiHandler';
import Fetch from '../fetch';

const END_POINTS = {
    prefix: 'Users',
    signIn: 'signIn',
    signUp: 'signUp',
    signOut: 'signOut',
    resetPassword: 'resetPassword',
    forgotPassword: 'forgotPassword'
}

class AuthApiHandler extends ApiHandler {
    async login(data) {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.signIn}`, data)
    }

    async register(data) {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.signUp}`, data)
    }

    async refreshToken() {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.signIn}`)
    }

    async forgetPassword(data) {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.forgotPassword}`, data)
    };

    async logout() {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.signOut}`)
    }

    async resetPassword(data) {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.resetPassword}`, data)
    }

    async signOut() {
        return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.signOut}`)
    }

}

const authApiHandler = new AuthApiHandler();
export default authApiHandler;