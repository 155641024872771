import React from 'react';
import { Select } from 'antd';
import useTranslation from 'hooks/useTranslation';

const { Option } = Select;

const TagsSelect = ({
						name,
						values,
						loading,
						onChange,
						allowClear,
						optionData,
						placeholder,
						data = [],
						width = 250,
						...restProps
					}) => {
	const translate = useTranslation();

	return (
		<Select
			showSearch
			name={name}
			mode="tags"
			loading={loading}
			style={{ width }}
			onChange={onChange}
			allowClear={allowClear}
			className="ant_des_select"
			placeholder={translate(placeholder)}
			{...restProps}
		>
			{
				data.map((item) => (
					<React.Fragment key={item}>
						<Option key={item}>{item}</Option>
					</React.Fragment>
				))
			}
		</Select>
	);
};

export default TagsSelect;