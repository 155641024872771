export const sendMessageFormDataMode = (data) => {
	const formData = new FormData();
	const { type, message, attachments, AWSTemplate } = data;
	formData.append('Type', type);
	formData.append('Message', message);
	formData.append('AWSTemplate', AWSTemplate);
	attachments?.length && attachments.forEach((item) => {
		formData.append(`Attachments`, item.blob, item.name);
	});

	return formData;
};