import React, { Fragment } from 'react';
import Select from '../select';
import { Select as AntdSelect } from 'antd';
import { IMG_URL } from 'core/constants/urls';
import errorFlag from 'assets/icons/finish.png';
import './index.scss';

const { Option } = AntdSelect;

/**
 * It's a Select component that takes in an array of objects, and returns a Select component with the data mapped to Option
 * components
 * @returns A Select component with a list of options.
 */

const LanguageSelect = ({
							data,
							value,
							noStyle,
							onChange,
							placeholder,
							width = 250,
							...restProps
						}) => {
	return (
		<Select
			data={data}
			value={value}
			width={width}
			onChange={onChange}
			placeholder={placeholder}
			className={noStyle ? 'language_select_no_style' : ''}
			filterOption={(input, option) => option.children.key.toLowerCase().includes(input.toLowerCase())}
			{...restProps}
		>
			{
				data.map((item) => {
					return (
						<Fragment key={item.id}>
							<Option value={`${item.id}`}>
								<span className="space_between" key={item.name}>
									{item.name}

									<img
										width="20"
										src={`${IMG_URL}/${item.flagUrl}`}
										onError={e => e.target.src = errorFlag} alt={item.name}
									/>
								</span>
							</Option>
						</Fragment>
					);
				})
			}
		</Select>
	);
};

export default LanguageSelect;