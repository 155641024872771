import React, { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { Pagination as AntPagination } from 'antd';
import createQuery from 'core/helpers/createQuery';
import { tablePageCalc } from 'core/helpers/tablePaginationCalc';
import './index.scss';

/**
 * It takes the current query string and query object, and returns a pagination component that updates the query string
 * when the page is changed
 * @returns A pagination component that is used to navigate through the table.
 */

const Pagination = ({ count }) => {
	const history = useHistory();
	const { queryString, queryObject } = useQuery();
	const [ page, setPage ] = useState(tablePageCalc(queryObject));

	useEffect(() => {
		setPage(tablePageCalc(queryObject));
	}, [ queryString ]);

	const handlePaginationChange = (current, pageSize) => {
		const skip = (current - 1) * pageSize;
		history.push(createQuery({
			...queryObject,
			skip,
			take: pageSize
		}));
	};

	return (
		<div className="flex_end costume_pagination">
			<AntPagination onChange={handlePaginationChange} current={page} total={count}/>
		</div>
	);
};

export default Pagination;