import T from 'core/translations/translations.json';

/* A constant that is used to validate phone numbers and integers. */
export const NUMBER_VALIDATIONS = {
	PHONE_NUMBER_VALIDATION: {
		pattern: new RegExp(/^\+?\d{0,25}$/),
		message: T.PHONE_NUMBER_VALIDATION_MESSAGE
	},

	INTEGER_NUMBER: {
		pattern: new RegExp(/^-?[0-9]*$/),
		message: T.INVALID_VALUE
	}
};

export const USER_DETAIL_VALIDATION = {
	FIRST_NAME_MAX_VALUE: {
		max: 250,
		message: T.FIRST_NAME_INPUT_WARNING_MESSAGE
	},
	LAST_NAME_MAX_VALUE: {
		max: 250,
		message: T.LAST_NAME_INPUT_WARNING_MESSAGE
	},
	HEADLINE: {
		max: 250,
		message: T.HEADLINE_INPUT_WARNING_MESSAGE
	}
}

/**
 * If the date is after today's date, or before 1960-01-01, then return true
 */
export const disabledDate = d => !d || d.isAfter(new Date().toLocaleDateString().replaceAll('/', '-')
) || d.isSameOrBefore("1960-01-01");

/* A constant that is used to validate password. */
export const INPUT_VALIDATION = {
	PASSWORD: {
		pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
		message: T.INVALID_PASSWORD
	}
};

export const bioMaxSymbol = 3000;
export const educationMaxSymbol = 250;
export const firstNameMaxSymbol = 100;
export const lastNameMaxSymbol = 100;
export const phoneNumberValidation = /^\+?\d{0,25}$/;
export const interestsMaxCount = 15;
export const skillsMaxCount = 15;
export const skillsMaxSymbol = 25;
export const interestsMaxSymbol = 20;
export const materialsMax = 10;
export const materialSize = 5000000;

export const scheduleMaxDuration = 1400;
export const scheduleMinDuration = 1;

export const courseTitleMaxSymbol = 300;
export const courseDescriptionMaxSymbol = 3000;
export const chatMessageMaxSymbol = 30000;
export const lessonTitleMaxSymbol = 250;
export const tagsMaxSymbol = 200;

export const urlsValidation = {
	linkedin: {
		url: 'linkedin.com',
		maxValue: 2048,
		maxValueMessage: T.LINKEDIN_MAX_VALUE,
		message: T.INVALID_LINKEDIN_URL
	}
};
/**
 * It checks if the interests array is valid
 * @param value - the value of the field
 * @param translate - a function that returns a translated string.
 * @returns A function that takes two arguments, value and translate.
 */
export const interestsValidation = (value, translate) => {
	const maxLengths = [];
	for (let i = 0; i < value.length; i++) {
		if(value[i].length > interestsMaxSymbol) {
			maxLengths.push(value[i]);
		}
	}

	if(value) {
		if(value.length > interestsMaxCount) {
			return Promise.reject(`${translate(T.INTERESTS_MAX_COUNT_SHOULD_BE)} ${interestsMaxCount} ${T.ITEM}`);
		}

		if(maxLengths.length) {
			return Promise.reject(`${translate(T.INVALID_INTERESTS)} (${maxLengths.join()}) ${translate(T.LENGTH_SHOULD_BE)} ${interestsMaxSymbol} ${translate(T.SYMBOL)}`);
		}

		return Promise.resolve();
	}
};

/**
 * It checks if the skills array has more than 5 items, and if any of the items has a label longer than 30 characters
 * @param value - the value of the field
 * @param translate - a function that returns a translated string.
 * @returns A function that takes two arguments, value and translate.
 */
export const skillsValidation = (value, translate) => {
	const maxLengths = [];
	for (let i = 0; i < value.length; i++) {
		if(value[i].label.length > skillsMaxSymbol) {
			maxLengths.push(value[i].label);
		}
	}

	if(value) {
		if(value.length > skillsMaxCount) {
			return Promise.reject(`${translate(T.SKILLS_MAX_COUNT_SHOULD_BE)} ${skillsMaxCount} ${T.ITEM}`);
		}

		if(maxLengths.length) {
			return Promise.reject(`${translate(T.INVALID_SKILLS)} (${maxLengths.join()}) ${translate(T.LENGTH_SHOULD_BE)} ${skillsMaxSymbol} ${translate(T.SYMBOL)}`);
		}
	}

	return Promise.resolve();
};


/**
 * It checks if the length of the tag name is more than the maximum allowed length
 * @param data - the data to be validated
 * @param translate - a function that returns a translated string.
 * @returns A function that takes in data and translate as parameters.
 */
export const tagsValidations = (data, translate) => {
	const maxLengths = [];
	for(let i = 0; i < data.length; i++) {
		if (data[i]?.name?.length > tagsMaxSymbol) {
			maxLengths.push(data[i]?.name)
		}
	}

	if (data) {
		if (maxLengths.length) {
			return Promise.reject(`${translate(T.TAGS)} ${translate(T.LENGTH_SHOULD_BE)} ${tagsMaxSymbol} ${translate(T.SYMBOL)}`);
		}
	}

	return Promise.resolve();
};
