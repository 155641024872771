import React, { useMemo } from 'react';
import { Form, Typography } from 'antd';
import useTranslation from 'hooks/useTranslation';

const { Text } = Typography;

/**
 * It's a wrapper for the Ant Design Form.Item component that adds some extra functionality
 * @returns A FormItem component
 */

const FormItem = ({
					  name,
					  label,
					  rules,
					  layout,
					  labelType = 'default',
					  requiredMessage,
					  children,
					  labelChildren,
					  ...restProps
				  }) => {
	const translate = useTranslation();

	const rulesData = useMemo(() => {
		let data = [];

		if(rules?.length) {
			data = rules;
		}

		if(requiredMessage) {
			data.push({
				required: true,
				message: translate(requiredMessage)
			});
		}

		return data;

	}, [ rules ]);

	const labelLayout = useMemo(() => {
		if(layout === 'vertical') {
			return ({
				labelCol: { span: 24 },
				wrapperCol: { span: 24 }
			});
		}

		if(layout === 'horizontal') {
			return ({
				labelCol: { span: 4 },
				wrapperCol: { span: 14 }
			});
		}
		return null;
	}, [ layout ]);

	return (
		<Form.Item
			name={name}
			label={labelChildren ? labelChildren : label ? <Text type={labelType}>{translate(label)}</Text> : null}
			rules={rulesData}
			{...labelLayout}
			{...restProps}
		>
			{children}
		</Form.Item>
	);
};

export default FormItem;