import { ROUTE_CONSTANTS } from 'routing/router';
import T from 'core/translations/translations.json';
import {
	AreaChartOutlined,
	BookOutlined,
	CommentOutlined,
	CopyrightOutlined,
	DollarCircleOutlined,
	MailOutlined,
	SafetyCertificateOutlined,
	ScheduleOutlined,
	TransactionOutlined,
	TranslationOutlined,
	UnorderedListOutlined,
	UsergroupAddOutlined,	
	ZhihuOutlined,
	TeamOutlined,
	SolutionOutlined,
	WindowsOutlined,
	MessageOutlined,
	SwitcherOutlined,
	ThunderboltOutlined,
	SettingOutlined,
	ReadOutlined,
	FundViewOutlined,
	DollarOutlined,
	WechatOutlined,
	DiffOutlined
} from '@ant-design/icons';
import React from 'react';

/* A constant that is used to create the navigation menu for the admin. */
export const ADMIN_NAVIGATION = [
	{
		name: T.DASHBOARD,
		to: ROUTE_CONSTANTS.DASHBOARD,
		icon: <AreaChartOutlined/>
	},
	{
		name: T.FINANCIALS,
		icon: <DollarCircleOutlined/>,
		subTo: ROUTE_CONSTANTS.REVENUES,
		subMenu: [
			{
				name: T.REVENUES,
				to: ROUTE_CONSTANTS.REVENUES,
				icon: <FundViewOutlined />
			},
			{
				name: T.PAYMENTS,
				to: ROUTE_CONSTANTS.PAYMENTS,
				icon: <DollarOutlined />
			}
		]
	},
	{
		name: T.LPM,
		to: T.LANDING_PAGE,
		icon: <BookOutlined/>,
		subTo: ROUTE_CONSTANTS.LANDING_PAGE,
		subMenu: [
			{
				name: T.CONTENT_MANAGEMENT,
				to: ROUTE_CONSTANTS.CONTENT_MANAGEMENT,
				icon: <SettingOutlined />
			},
			{
				name: T.NEWS_SUBSCRIPTIONS,
				to: ROUTE_CONSTANTS.NEW_SUBSCRIBERS,
				icon: <ReadOutlined />
			},
			{
				name: T.COURSES_CAROUSEL,
				to: ROUTE_CONSTANTS.COURSES_CAROUSEL,
				icon: <SwitcherOutlined />
			},
			{
				name: T.MENU,
				to: ROUTE_CONSTANTS.MENU_ITEMS,
				icon: <WindowsOutlined />
			},

			{
				name: T.USER_MESSAGES,
				to: ROUTE_CONSTANTS.USER_MESSAGES,
				icon: <MessageOutlined />
			},
			{
				name: T.PROMOTION_BANNER,
				to: ROUTE_CONSTANTS.PROMOTION_BANNER,
				icon: <ThunderboltOutlined />
			},
			{
				name: T.INSTRUCTORS,
				to: ROUTE_CONSTANTS.INSTRUCTORS,
				icon: <TeamOutlined/>
			},
			{
				name: T.EMAIL_TEMPLATES,
				to: ROUTE_CONSTANTS.EMAIL_TEMPLATES,
				icon: <MailOutlined/>
			},
			{
				name:T.DYNAMIC_PAGES,
				to: ROUTE_CONSTANTS.DYNAMIC_PAGES,
				icon:<DiffOutlined/>
			}
		]
	},
	{
		name: T.LANGUAGES,
		to: T.LANGUAGES,
		icon: <ZhihuOutlined />,
		subTo: ROUTE_CONSTANTS.LANGUAGES,
		subMenu: [
			{
				name: T.LANGUAGES,
		to: ROUTE_CONSTANTS.LANGUAGES,
		icon: <TransactionOutlined/>
			},
			{
				name: T.TRANSLATIONS,
		to: ROUTE_CONSTANTS.TRANSLATIONS,
		icon: <TranslationOutlined/>
			}			
		]
	},
	{
		name: T.USER_MANAGEMENT,
		to: T.USER_MANAGEMENT,
		icon: <TeamOutlined />,
		subTo: ROUTE_CONSTANTS.USER_MANAGEMENT,
		subMenu: [
			{
				name: T.USER_MANAGEMENT,
				to: ROUTE_CONSTANTS.USER_MANAGEMENT,
				icon: <UsergroupAddOutlined/>
			},
			{
				name: T.CHAT,
				to: ROUTE_CONSTANTS.CHAT,
				icon: <WechatOutlined />
			},
			{
				name: T.PROFILE_SETUP,
				to: ROUTE_CONSTANTS.PROFILE_SETUP,
				icon: <SolutionOutlined />
			},
			{
				name: T.CERTIFICATES,
				to: ROUTE_CONSTANTS.CERTIFICATES,
				icon: <SafetyCertificateOutlined/>
			}			
		]
	},
	{
		name: T.COURSES,
		to: ROUTE_CONSTANTS.COURSES,
		icon: <BookOutlined/>,
		subTo: ROUTE_CONSTANTS.COURSES,
		subMenu: [
			{
				name: T.COURSES,
				to: ROUTE_CONSTANTS.COURSES,
				icon: <BookOutlined/>
			},
			{
				name: T.SCHEDULE,
				to: ROUTE_CONSTANTS.SCHEDULE,
				icon: <ScheduleOutlined/>
			},
			{
				name: T.CATEGORIES,
				to: ROUTE_CONSTANTS.CATEGORIES,
				icon: <UnorderedListOutlined/>
			},
			{
				name: T.COUPONS,
				to: ROUTE_CONSTANTS.COUPONS,
				icon: <CopyrightOutlined/>
			},
			{
				name: T.FEEDBACKS,
				to: ROUTE_CONSTANTS.FEEDBACKS,
				icon: <CommentOutlined/>
			}
		]
	}	
];
