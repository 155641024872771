import Enum from './enumCreator';

export const userTypes = Enum('ADMIN', 'MANAGER', 'INSTRUCTOR', 'STUDENT')(1);
export const profileUserTypes = Enum('INSTRUCTOR', 'STUDENT')(3);
export const userRouteTypes = Enum('admin', 'manager', 'instructor', 'student')(1);
export const languageStatus = Enum('draft', 'updated', 'published')(1);
export const userStatus = Enum('NEW', 'PENDING_ACTIVATION', 'PENDING_PROFILE', 'ACTIVE', 'PENDING_REVIEW', 'BLOCK', 'DECLINED')(0);
export const courseStatus = Enum('DRAFT', 'PUBLISH_ACTIVE', 'SUSPENDED')(1);
export const courseTypes=Enum('COURSES', 'MENTORING_PROGRAMS')(0);
export const transactionStatus = Enum('PENDING', 'PAYED', 'CANCELED','PARTIAL_REFUNDED', 'REFUNDED')(1);
export const registrationPaymentStatus = Enum('PENDING', 'PARTIALLY', 'DONE', 'CANCELLED_WITH_NO_REFUNDING', 'CANCELLED_WITH_PARTIALLY_REFUNDING', 'CANCELLED_WITH_TOTAL_REFUNDING')(1);
export const feedbacksType = Enum('COURSE', 'INSTRUCTOR')(1);
export const couponType = Enum('FIXED', 'PERCENTAGE')(1);
export const couponStatus = Enum('ACTIVE', 'DEACTIVATED', 'EXPIRED')(1);
export const iconButtonTypes = Enum('block', 'default')(1);
export const instructorTitle = Enum('NONE', 'DR', 'PHD', 'MS')(0);

export const experienceLevel = Enum('BEGINNER', 'INTERMEDIATE', 'EXPERIENCED', 'ADVANCED', 'EXPERT')(1);
export const educationLevel = Enum('BEGINNER', 'INTERMEDIATE', 'PROFESSIONAL')(1);
export const scheduleStatusByText = Enum('DRAFT', 'PLANNED', 'IN_PROGRESS', 'COMPLETED', 'CANCELLED', 'TERMINATED','ACTIVATED', 'ACCEPTED', 'EXPERTFINALIZED', 'MAINTANANCE', 'CLOSED', 'REFUNDED', 'PARTIALREFUNDED')(1);
export const scheduleStatusByClassName = Enum('draft', 'planned', 'in_progress', 'completed', 'cancelled', 'terminated','activated', 'accepted', 'expert_finalized', 'maintanance', 'closed', 'refunded', 'partiall_refunded')(1);
export const scheduleStatus = Enum('DRAFT', 'PLANNED', 'IN_PROGRESS', 'COMPLETED', 'CANCELLED', 'TERMINATED', 'ACTIVATED', 'ACCEPTED', 'EXPERTFINALIZED', 'MAINTANANCE', 'CLOSED', 'REFUNDED', 'PARTIALREFUNDED')(1);

export const schedulePaymentTypeText = Enum('ONE_TIME', 'RECURRING')(1);

export const schedulePaymentTypeClassName = Enum('one_time', 'recurring')(1);
export const landingPageSectionType = Enum('ABOUT', 'JOIN_PLATFORM', 'REQUEST_COURSE', 'OUR_COURSES', 'OUR_INSTRUCTORS', 'PROMOTION_BANNER', 'CONTACT_US', 'NEWS_LETTER', 'YOUTUBE_VIDEO')(1);
export const landingPageMessageStatus = Enum('NEW', 'READ', 'RESPONDED', 'PENDING_RESPONSE', 'ISSUE', 'CLOSED', 'ABANDONED')(1);
export const chatChannelState = Enum('OPEN', 'CLOSED', 'SUSPENDED', 'ARCHIVED')(1);
export const chatChannelReceipientState = Enum('ACTIVE', 'PENDING', 'LEFT', 'SUSPENDED', 'BLOCKED')(1);
export const chatMessageState = Enum('NEW', 'SENT', 'SENT_BY_EMAIL', 'DELIVERED', 'DELIVERED_BY_EMAIL', 'DELETED')(1);
export const chatMessageStatus = Enum('UN_READ', 'READ')(1);
export const chatMessageType = Enum('MESSAGE', 'EMAIL')(1);
export const magicActionTypes=Enum('SCHEDULE_START','EXPERT_COMPLETED', 'CLIENT_REVIEW','CLIENT_FEEDBACK', 'CLIENT_INVITE', 'EXPERT_CREATE_SCHEDULE')(1);
