/**
 * If isShow is true, return children, otherwise return null.
 * @returns if isShow is true, then children is returned
 * 	if isShow is false, then emptyValue is returned
 * 	if isShow is false and emptyValue is false, then null is returned
 */
const ShowElement = ({ children, isShow, emptyValue }) => {
	return isShow ? children : emptyValue ? emptyValue : null;
};

export default ShowElement;