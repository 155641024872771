import React, { useState } from 'react';
import { Input } from 'antd';
import { noop } from 'core/constants/utils';
import useTranslation from 'hooks/useTranslation';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import './index.scss';

const TextInput = ({
					   name,
					   placeholder,
					   onChange = noop,
					   requiredMessage,
					   keepReadOnlyStyle,
					   value: controlledValue,
					   readOnly = false,
					   ...restProps
				   }) => {
	const [ value, setValue ] = useState(controlledValue);
	const translate = useTranslation();

	useDidUpdateEffect(() => {
		setValue(controlledValue);
	}, [ controlledValue ]);

	const handleChange = (event) => {
		let { value = '' } = event.target;
		value = value?.replace(/^\s+/g, '');
		setValue(value);
		event.target.value = value;
		onChange(event);
	};

	return (
		<Input
			type="text"
			name={name}
			readOnly={readOnly}
			value={value ?? ''}
			onChange={handleChange}
			placeholder={translate(placeholder)}
			className={`text_input ${keepReadOnlyStyle ? 'keep_read_only_style' : ''} ${readOnly ? 'readonly_text_input' : ''}`}
			{...restProps}
		/>
	);
};

export default TextInput;