export const DEFAULT_SELECTOR_VALUE = '10';


/* Creating an array of numbers that will be used to populate the dropdown menu. */
export const SELECTOR_DATA = [
	DEFAULT_SELECTOR_VALUE, '20', '50', '100', '500'
];


export const DEFAULT_SKIP_TAKE = {
	skip: 0,
	take: DEFAULT_SELECTOR_VALUE
};