import React, { useEffect, useState } from 'react';
import { message, Upload } from 'antd';
import { IMG_URL } from 'core/constants/urls';
import useTranslation from 'hooks/useTranslation';
import { getBase64 } from 'core/helpers/getBase64';
import T from 'core/translations/translations.json';
import Typography from 'view/components/shared/typography';
import { InboxOutlined } from '@ant-design/icons';
import './index.scss';

const { Dragger } = Upload;
const { BaseBodyText } = Typography;

const acceptFileTypes = [ 'image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml' ];
const minWidth = 1000;
const minHeight = 500;
const maxWidth = 2000;
const maxHeight = 1080;

const checkImgType = (name, type) => name.includes(type);

const UploadDrag = ({
						value,
						imagePath,
						onSetFileData,
						onChange,
						disabled = false
					}) => {
	const [ imageUrl, setImageUrl ] = useState();
	const translate = useTranslation();

	/**
	 * It takes an object with a width and height property, and returns a message.error with a string that contains the width
	 * and height of the image, the max width and height, and the min width and height
	 */
	const sizeErrorMessage = ({ width, height }) => {
		message.error(
			<span>
				<BaseBodyText>
					{translate(T.SOCIAL_IMAGE_SIZE_WARNING_TEXT)}
					{' '}
					<BaseBodyText fontWeight="medium">
						({width} x {height})
					</BaseBodyText>
				</BaseBodyText>
				<br/>
				<BaseBodyText>
					{translate(T.MAX_SIZE)}
					{' '}
					<BaseBodyText fontWeight="medium">
						({maxWidth} x {maxHeight})
					</BaseBodyText>
				</BaseBodyText>

				<br/>

				<BaseBodyText>
					{translate(T.MIN_SIZE)}
					{' '}
					<BaseBodyText fontWeight="medium">
						({minWidth} x {minHeight})
					</BaseBodyText>
				</BaseBodyText>
			</span>
		);
	};

	useEffect(() => {
		if(imagePath) {
			setImageUrl(`${IMG_URL}/${imagePath}`);
		}

		if(value) {
			setImageUrl(`${IMG_URL}/${value}`);
		}
	}, [ imagePath, value ]);

	/**
	 * It checks if the file type is either jpg or png. If it is, it returns true. If it isn't, it returns false
	 * @param file - The file object that is being uploaded.
	 * @returns isJpgOrPng
	 */
	const beforeUpload = (file) => {
		const { name } = file;
		const isJpgOrPng = acceptFileTypes.includes(file.type);
		if(checkImgType(name, '.jfif')) {
			message.error(translate(T.IMG_TYPE_WARNING));
		}
		if(!isJpgOrPng) {
			message.error(translate(T.IMG_TYPE_WARNING));
		}
		return isJpgOrPng;
	};

	/* Setting the props for the Dragger component. */
	const fileUploadProps = {
		name: 'file',
		fileList: [],
		beforeUpload,
		multiple: false,
		showUploadList: false,
		accept: acceptFileTypes.join(', '),
		customRequest: () => {
			return Promise.resolve();
		},
		onChange(info) {
			const { file } = info;
			const { name } = info.file;

			file.originFileObj && getBase64(file.originFileObj, (imageUrl) => {
				const image = new Image();
				image.src = imageUrl;

				image.onload = function () {
					const { width, height } = image;
					if(width > maxWidth || height > maxHeight) {
						if(!checkImgType(name, '.jfif')) {
							sizeErrorMessage({ width, height });
						}
						return null;
					}

					if(width < minWidth || height < minHeight) {
						if(!checkImgType(name, '.jfif')) {
							sizeErrorMessage({ width, height });
						}
						return null;
					}

					setImageUrl(imageUrl);
					onChange({
						blob: info.file.originFileObj,
						name: info.file.name
					});
					onSetFileData({
						blob: info.file.originFileObj,
						name: info.file.name
					});
				};
			});

		}
	};

	return (
		<div className="upload_drag">
			<Dragger
				disabled={disabled}
				{...fileUploadProps}
			>
				{
					!imageUrl ? (
						<div>
							<p className="ant-upload-drag-icon">
								<InboxOutlined/>
							</p>

							{/*Click or drag file to this area to upload*/}
							<p className="ant-upload-text">{translate(T.DRAG_UPLOAD_TITLE)}</p>
							<p className="ant-upload-hint">{translate(T.DRAG_UPLOAD_DESCRIPTION)}</p>
						</div>
					) : (
						<div className="img-content" style={{ backgroundImage: `url(${imageUrl})` }}/>
					)
				}
			</Dragger>

		</div>
	);
};

export default UploadDrag;