import ApiHandler from '../apiHandler';
import Fetch from '../fetch';

const END_POINTS = {
	prefix: 'Languages',
	texts: 'texts',
	publish: 'publish',
	predefined: 'predefined'
};

class LanguagesApiHandler extends ApiHandler {
	async getLanguages(filter) {
		return Fetch.get(END_POINTS.prefix, filter);
	}

	async getLanguagesText(id, filter) {
		return Fetch.get(`${END_POINTS.prefix}/${id}/${END_POINTS.texts}`, filter);
	}

	async addLanguage(data) {
		return Fetch.post(END_POINTS.prefix, data);
	}

	async getPredefinedLanguage(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.predefined}`, filter);
	}

	async deleteLanguage(id) {
		return  Fetch.delete(`${END_POINTS.prefix}/${id}`);
	}

	async publishLanguage(id) {
		return Fetch.post(`${END_POINTS.prefix}/${id}/${END_POINTS.publish}`);
	}
}

const languagesApiHandler = new LanguagesApiHandler();
export default languagesApiHandler;