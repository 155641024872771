import { Suspense, useEffect, useContext } from 'react';
import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import Header from 'view/components/global/header';
import Sidebar from 'view/components/global/sidebar';
import Loading from 'view/components/global/loading';
import authStore from 'stateManagement/stores/authStore';
import { LanguageContext, TranslationContext } from 'context';
import RouterOutlet from 'view/components/global/routerOutlet';
import SidebarWrapper from 'view/components/global/sidebarWrapper';
import languagesStore from 'stateManagement/stores/languagesStore';
import { hubConnection } from 'core/services/signalrService/createHubConnection';
import './index.scss';

const App = () => {
	const { isAuth } = authStore;
	const { language } = useContext(LanguageContext);
	const { languagesForProject: { data, isLoading } } = languagesStore;

	useEffect(() => {
		isAuth && createHubConnection();
	}, [ isAuth ]);

	useEffect(() => {
		languagesStore.getLanguagesForProject(language.id);
	}, [ language ]);

	const createHubConnection = async () => {
		try {
			hubConnection.connectedAllSignalrEvent();
			await hubConnection.connection.start();
		} catch (error) {
			console.log(error);
		}
	};

	if(isLoading) {
		return <Loading/>;
	}

	return (
		<TranslationContext.Provider value={data}>
			<Layout className="main-container">
				<SidebarWrapper>
					<Sidebar/>
				</SidebarWrapper>

				<Layout className="site-layout">
					<Header/>

					<Suspense fallback={<Loading/>}>
						<RouterOutlet/>
					</Suspense>
				</Layout>
			</Layout>
		</TranslationContext.Provider>
	);
};

export default observer(App);
