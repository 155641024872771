import { encodeObject } from './encodeObject';
import TypeChecker from './typeChecker';


/**
 * It takes an object, array, string or number and returns a query string
 * @returns A function that takes in a queryFields parameter.
 */

const createQuery = queryFields => {
    // when queryFields is undefined
    if (!queryFields) {
        return '';
    }

    // when queryFields is string or number
    if (typeof queryFields === 'string' || typeof queryFields === 'number') {
        return `${queryFields}`;
    }

    // when queryFields is array
    if (Array.isArray(queryFields)) {
        let query = '?';
        queryFields.forEach((queryField, index) => {
            if (index) {
                query += `&${queryField.key}=${encodeURIComponent(queryField.value)}`;
            } else {
                query += `${queryField.key}=${encodeURIComponent(queryField.value)}`;
            }
        });
        return query;
    }

    // when queryFields is object
    if (Object.keys(queryFields).length) {
        let searchQuery = '';
        for (const key in queryFields) {
            if (Array.isArray(queryFields[key])) {
                // eslint-disable-next-line no-loop-func
                queryFields[key].forEach(value => {
                    searchQuery = `${searchQuery}&${key}=${encodeURIComponent(value)}`;
                });
            }
            /* check if query object prop is object too */
            else if (typeof queryFields[key] === 'object' && !TypeChecker.isNull(queryFields[key])) {
                searchQuery = `${searchQuery}&${encodeObject(queryFields[key])}`;
            } else {
                if(!TypeChecker.isUndefined(queryFields[key]) &&
                    !TypeChecker.isNull(queryFields[key]) &&
                    !TypeChecker.isEmptyString(queryFields[key])){
                    searchQuery = `${searchQuery}&${key}=${encodeURIComponent(queryFields[key])}`;
                }
            }
        }
        return `?${searchQuery.substring(1)}`;
    }
    return '';
};

export default createQuery;
