import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import authStore from 'stateManagement/stores/authStore';
import { ROUTE_CONSTANTS } from '../router';

/**
 * It returns the children if the isForgetPassword property of the authStore is true, otherwise it redirects to the empty
 * route
 * @returns The children of the component.
 */

const IsForgetPassword = ({ children }) => {
	const { isForgetPassword } = authStore;
	return (
		<>
			{isForgetPassword ? children : <Redirect to={ROUTE_CONSTANTS.EMPTY}/>}
		</>
	);
};

export default observer(IsForgetPassword);