/**
 * @param {object} obj object to encode
 * @returns string
 */
export const encodeObject = obj => {
    let str = '';
    Object.entries(obj).forEach(([key, value]) => {
        if (value instanceof Object) {
            str += `${key}=${encodeURIComponent(JSON.stringify(value))}&`;
        } else if (value !== undefined) {
            str += `${key}=${value}&`;
        }
    });
    if (str[str.length - 1] === '&') {
        str = str.slice(0, str.length - 1);
    }
    return str;
};
