import React, { memo } from 'react';
import { Tooltip } from 'antd';
import useTranslation from 'hooks/useTranslation';
import './index.scss';

/**
 * It's a function that returns a tooltip with an image inside
 * @returns A React component that is a Tooltip with an image inside.
 */

const ImgIcon = ({ placement = 'topRight', onClick, src, title, width = '25', disabled, ...restProps }) => {
	const translate = useTranslation();

	return (
		<Tooltip
			onClick={onClick}
			placement={placement}
			title={translate(title)}
			className={`icon-img-content ${disabled ? 'disabled' : ''}`}
		>
			<img
				src={src}
				width={width}
				alt={translate(title)}
				{...restProps}
			/>
		</Tooltip>
	);
};

export default memo(ImgIcon);