import { action, makeObservable, observable } from 'mobx';
import StoreBase from '../storeBase';
import usersApiHandler from 'core/services/apiService/apiHandlers/usersApiHandler';
import { downloadStreamFile } from '../../core/helpers/downloadStreamFile';
import authStore from 'stateManagement/stores/authStore';

class UsersStore extends StoreBase {
	meData = this.toState({

	});

	constructor() {
		super();
		makeObservable(this, {
			meData: observable,
			setMeData: action
		});
	}

	setMeData(meData) {
		this.meData = meData;
	};

	async setActivateKey(activationKey) {
		const res = await usersApiHandler.setActivateKey(activationKey);
		return this.isOk(res);
	}

	async getProfile() {
		const res = await usersApiHandler.getProfile();
		console.log(res);
	}

	async getInstructorProfileResume({ cvPath, ...restData }) {
		const resp = await usersApiHandler.getInstructorProfileResume(restData);
		downloadStreamFile(resp, cvPath);
	}

	 async getMe() {
		const res = await usersApiHandler.getMe();
		if(res?.result?.type===1 || res?.result?.type===2){
		this.setMeData(this.toState(this.meData.data, true));		
		const result = res?.result ? this.toState(res.result) : { ...this.meData, isLoading: false };
		this.setMeData(result);
		return true;
		}
		else{
			authStore.logout();
			return false;
		}
	}

	async isUserAdminOrManager(){
		if(this.meData?.type===1 || this.meData.type===2){
			return true;			
		}else{
			return false
		}
	}

	async addInstructorProfile(formData) {
		const res = await usersApiHandler.addInstructorProfile(formData);
		return this.isOk(res);
	}

	async editInstructorProfile(formData) {
		const res = await usersApiHandler.editInstructorProfile(formData);
		return this.isOk(res);
	}	
}

const userStore = new UsersStore();
export default userStore;