import { useContext } from 'react';
import MainHeader from './mainHeader';
import { observer } from 'mobx-react-lite';
import CabinetHeader from './cabinetHeader';
import { LanguageContext } from 'context';
import authStore from 'stateManagement/stores/authStore';
import languagesStore from 'stateManagement/stores/languagesStore';
import './index.scss';

const HeaderLayout = () => {
	const { isAuth } = authStore;
	const { languages: { data: { items: languagesList } } } = languagesStore;
	const { language, setLanguage } = useContext(LanguageContext);
	const Component = isAuth ? CabinetHeader : MainHeader;

	const handleChangeProjectLanguage = (languageId) => {
		const findLanguage = languagesList.find((item) => item.id === +languageId);
		if(findLanguage) {
			const { status, ...restData } = findLanguage;
			setLanguage(restData);
		}
	};

	return (
		<Component
			languageId={language.id}
			languagesList={languagesList}
			onChangeLanguage={handleChangeProjectLanguage}
		/>
	);
};

export default observer(HeaderLayout);