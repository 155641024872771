import { DOMAIN_API } from 'core/constants/urls';
import createQuery from 'core/helpers/createQuery';
import buildHeaders from 'core/helpers/buildHeaders';
import { errorHandler } from 'core/helpers/errorHandler';
import LocalStorageHelper from 'core/helpers/localStorageHelper';
import { HTTP_METHODS } from 'core/constants/apiConstants/httpMethods';
import { CONTENT_TYPES } from 'core/constants/apiConstants/contentTypes';
import authStore, { ACCESS_TOKEN } from 'stateManagement/stores/authStore';
import { DEFAULT_OPTIONS } from 'core/constants/apiConstants/requestDefaults';

/* It's a wrapper for the native fetch API */
class Fetch {
	static token = '';
	static domainApi = DOMAIN_API;

	/**
	 * @param {string} url
	 * @param {string | object | Array<object>} queryData
	 * @param {object | null} options
	 * @returns
	 */
	static get(url, queryData, options = DEFAULT_OPTIONS) {
		options.method = HTTP_METHODS.GET;
		return this.request(`${url}${createQuery(queryData)}`, options);
	}

	/**
	 * @param {string} url
	 * @param {any} data
	 * @param {object | null} options
	 * @returns
	 */
	static post(url, data, options = DEFAULT_OPTIONS) {
		options.method = HTTP_METHODS.POST;
		options.data = data;
		return this.request(url, options);
	}

	/**
	 * @param {string} url
	 * @param {any} data
	 * @param {object | null} options
	 * @returns
	 */
	static put(url, data, options = DEFAULT_OPTIONS) {
		options.method = HTTP_METHODS.PUT;
		options.data = data;
		return this.request(url, options);
	}

	static patch(url, data, options = DEFAULT_OPTIONS) {
		options.method = HTTP_METHODS.PATCH;
		options.data = data;
		return this.request(url, options);
	}

	/**
	 * @param {string} url
	 * @param {any} data
	 * @param {object | null} options
	 * @returns
	 */
	static delete(url, data, options = DEFAULT_OPTIONS) {
		options.method = HTTP_METHODS.DELETE;
		options.data = data;
		return this.request(url, options);
	}

	/**
	 * @param {string} url
	 * @param {object} options
	 * @returns result
	 */
	static async request(url, options) {
		const {
			method,
			data = DEFAULT_OPTIONS.data,
			signal = DEFAULT_OPTIONS.signal,
			version = DEFAULT_OPTIONS.version,
			contentType = DEFAULT_OPTIONS.contentType
		} = options;
		const headers = buildHeaders(contentType, Fetch.token);
		const body = method !== HTTP_METHODS.GET ? contentType === CONTENT_TYPES.APPLICATION_JSON ? JSON.stringify(data) : data : null;
		const response = await Promise.race([ this.uniqueRequest(`${version}/${url}`, method, headers, body, signal) ]);

		const responseHeaderToken = response?.headers.get(ACCESS_TOKEN);
		if(responseHeaderToken) {
			LocalStorageHelper.deleteItem(ACCESS_TOKEN);
			Fetch.token = responseHeaderToken;
			LocalStorageHelper.setItem(ACCESS_TOKEN, responseHeaderToken);
		}

		return await errorHandler(response);
	}

	/**
	 * @param {string} url
	 * @param {string} method
	 * @param {object} headers
	 * @param {object | null} body
	 * @param {object | null} signal
	 * @returns Promise<Response>
	 */
	static async uniqueRequest(url, method, headers, body, signal) {
		// this method will be used to make requests unique
		try {
			return await fetch(`${this.domainApi}/${url}`, {
				method,
				headers,
				body,
				signal,
				credentials: DEFAULT_OPTIONS.credentials
			});
		} catch (error) {
			Fetch.token = '';
			window.location.reload();
			authStore.setIsAuth(false);
			LocalStorageHelper.deleteItem('token');
		}
	}
}

export default Fetch;
