import React from 'react';
import { notification } from 'antd';
import { ToasterDescription, ToasterTitle } from 'view/components/shared';
import T from 'core/translations/translations.json';

/**
 * It takes an object with a title, message, placement, and duration, and returns an object with a message, description,
 * placement, and duration
 */
const createNotificationContent = ({ title, message, placement = 'topRight', duration = 4.5 }) => ({
	message: <ToasterTitle title={title}/>,
	description: <ToasterDescription description={message}/>,
	placement,
	duration
});

/**
 * Create a notification with the error type and the given title and restData.
 */
export const errorNotification = ({ title = T.ERROR, ...restData }) => {
	notification.error(createNotificationContent({ title, ...restData }));
};

/**
 * Create a notification with the given title and restData, and display it as a success notification.
 */
export const successNotification = ({ title = T.ERROR, ...restData }) => {
	notification.success(createNotificationContent({ title, ...restData }));
};

/**
 * This function creates a warning notification with the given title and rest of the data.
 */
export const warningNotification = ({ title = T.ERROR, ...restData }) => {
	notification.warning(createNotificationContent({ title, ...restData }));
};