import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'routing/router';
import authStore from 'stateManagement/stores/authStore';

/**
 * If the user is not authenticated, or if the user is on the reset password page, return null. Otherwise, return the
 * children
 * @returns The children of the SidebarWrapper component.
 */
const SidebarWrapper = ({ children }) => {
	const { pathname } = useLocation();
	const { isAuth } = authStore;

	if(!isAuth) {
		return null;
	}

	if(pathname === ROUTE_CONSTANTS.RESET_PASSWORD) {
		return null;
	}

	return children;
};

export default observer(SidebarWrapper);