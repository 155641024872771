import StoreBase from '../storeBase';
import { action, makeObservable, observable } from 'mobx';
import landingPageApiHandler from 'core/services/apiService/apiHandlers/landingPageApiHandler';
import {
	createAddProposalsFormDataModel, createImportSubscribersFormDataModel,
	editSectionFormDataModel,
	sectionContentInitialState,
	sectionDataModel,
	dynamicPageContentInitialState
} from './helpers/landingPage';

class LandingPageStore extends StoreBase {
	message = this.toState({
		responses: [],
		comments: []
	});
	sections = this.toState([]);
	proposals = this.toState([]);
	sectionContent = this.toState(sectionContentInitialState);
	configurations = this.toState({
		selectedCoursesMaxCount: 0,
		promotionBannersMaxCount: 0,
		selectedInstructorsMaxCount: 0
	});
	courses = this.toState([]);
	newsHistoryUsers = this.toState([]);
	instructors = this.toState([]);
	messages = this.toState({ filteredCount: 0, items: [] });
	menuItems = this.toState({ filteredCount: 0, items: [] });
	subscribers = this.toState({ filteredCount: 0, items: [] });
	newsHistory = this.toState({ filteredCount: 0, items: [] });
	magicAction=this.toState({});
	magicReviewAction=this.toState({});
	dynamicPageContent=this.toState(dynamicPageContentInitialState);
	magicActonCourses=this.toState({courses:[]});
	magicClientInfo=this.toState({});

	constructor() {
		super();
		makeObservable(this, {
			menuItems: observable,
			message: observable,
			sections: observable,
			sectionContent: observable,
			proposals: observable,
			newsHistoryUsers: observable,
			newsHistory: observable,
			courses: observable,
			subscribers: observable,
			messages: observable,
			configurations: observable,
			instructors: observable,
			setMenuItems: action,
			setSubscribers: action,
			setMessages: action,
			setMessage: action,
			setNewsHistory: action,
			setNewsHistoryUsers: action,
			setSections: action,
			setProposals: action,
			setConfigurations: action,
			setCourses: action,
			setSectionContent: action,
			setInstructors: action,
			setMagicAction:action,
			magicAction:observable,
			setMagicReviewAction:action,
			magicReviewAction:observable,
			setDynamicPageContent: action,
			dynamicPageContent: observable,
			setMagicActonCourses:action,
			magicActonCourses: observable,
			setMagicClientInfo: action,
			magicClientInfo:observable
		});
	}
	
	setMenuItems(menuItems) {
		this.menuItems = menuItems;
	};

	setDynamicPageContent(dynamicPageContent){
		this.dynamicPageContent=dynamicPageContent;
	}

	setSubscribers(subscribers) {
		this.subscribers = subscribers;
	}

	setSections(sections) {
		this.sections = sections;
	}

	setMessage(message) {
		this.message = message;
	}

	setMessages(messages) {
		this.messages = messages;
	};

	setNewsHistory(newsHistory) {
		this.newsHistory = newsHistory;
	}

	setNewsHistoryUsers(newsHistoryUsers) {
		this.newsHistoryUsers = newsHistoryUsers;
	}

	setProposals(proposals) {
		this.proposals = proposals;
	}

	setConfigurations(configurations) {
		this.configurations = configurations;
	}

	setCourses(courses) {
		this.courses = courses;
	}

	setInstructors(instructors) {
		this.instructors = instructors;
	}

	setSectionContent(sectionContent) {
		this.sectionContent = sectionContent;
	}

	setMagicAction(data){
		this.magicAction=data;
	}	
	setMagicReviewAction(data){
		this.magicReviewAction=data;
	}

	setMagicClientInfo(data){
		this.magicClientInfo=data;
	}	
	
	setMagicActonCourses(data){
		this.magicActonCourses=data;
	}

	async getMagicAction(key){
		this.setMagicAction(this.toState(this.magicAction.data, true));
		const res= await landingPageApiHandler.getMagicActionResponse(key);
		const result = res?.result ? this.toState(res.result) : { ...this.magicAction, isLoading: false };
		this.setMagicAction(result);
	}

	async submitReviewMagicAction(data){		
		this.setMagicReviewAction(this.toState(this.magicReviewAction.data, true));
		const res= await landingPageApiHandler.processMagicAction(data);		
		const result = res?.result ? this.toState(res.result) : { ...this.magicReviewAction, isLoading: false };
		this.setMagicReviewAction(result);
		return res;
	}

	async getMagicClientInfo({key, email, phone}){
		this.setMagicClientInfo(this.toState(this.magicClientInfo.data,true));
		const res=await landingPageApiHandler.getMagicSearchClient({actionKey:key,email,phone});
		const result=res?.result? this.toState(res.result) : {...this.magicClientInfo,isLoading:false};
		this.setMagicClientInfo(result);
	}

	async getMagicActonCourses(id){
		this.setMagicActonCourses(this.toState(this.magicActonCourses.data,true));
		const res=await landingPageApiHandler.getMagicActonCourses(id);
		const result=res?.result ? this.toState({courses:res.result.items}) : {...this.magicActonCourses, isLoading: false};
		this.setMagicActonCourses(result);
	}

	async getMenuItems(filter) {
		this.setMenuItems(this.toState(this.menuItems.data, true));
		const res = await landingPageApiHandler.getMenuItems(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.menuItems, isLoading: false };
		this.setMenuItems(result);
	}

	async updateMenuItems(data) {
		const res = await landingPageApiHandler.updateMenuItems(data);
		return this.isOk(res);
	}

	async getSections(filter) {
		this.setSections(this.toState(this.sections.data, true));
		const res = await landingPageApiHandler.getSections(filter);
		const result = res?.result ? this.toState(sectionDataModel(res.result)) : {
			...this.sections,
			isLoading: false
		};
		this.setSections(result);
	}

	async getSubscribers(filter) {
		this.setSubscribers(this.toState(this.subscribers.data, true));
		const res = await landingPageApiHandler.getSubscribers(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.subscribers, isLoading: false };
		this.setSubscribers(result);
	}

	async getMessages(filter) {
		this.setMessages(this.toState(this.messages.data, true));
		const res = await landingPageApiHandler.getMessages(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.messages, isLoading: false };
		this.setMessages(result);
	}

	async getMessage(id) {
		this.setMessage(this.toState(this.message.data, true));
		const res = await landingPageApiHandler.getMessage(id);
		const result = res?.result ? this.toState(res.result) : { ...this.message, isLoading: false };
		this.setMessage(result);
	}


	async getNewsHistory(filter) {
		this.setNewsHistory(this.toState(this.newsHistory.data, true));
		const res = await landingPageApiHandler.getNewsHistory(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.newsHistory, isLoading: false };
		this.setNewsHistory(result);
	}

	async getNewsHistoryUsers(id) {
		this.setNewsHistoryUsers(this.toState(this.newsHistoryUsers.data, true));
		const res = await landingPageApiHandler.getNewsHistoryUsers(id);
		const result = res?.result ? this.toState(res.result) : { ...this.newsHistoryUsers, isLoading: false };
		this.setNewsHistoryUsers(result);
	}

	async sendMessageComments(data) {
		const res = await landingPageApiHandler.sendMessageComments(data);
		return this.isOk(res);
	}

	async editMessageResponses(data) {
		const res = await landingPageApiHandler.editMessageResponses(data);
		return this.isOk(res);
	}

	async changeMessageStatus(data) {
		const res = await landingPageApiHandler.changeMessageStatus(data);
		return this.isOk(res);
	}

	async isShowLandingPageSection(data) {
		const res = await landingPageApiHandler.isShowLandingPageSection(data);
		return this.isOk(res);
	}

	async reorderSections(data) {
		const res = await landingPageApiHandler.reorderSections(data);
		return this.isOk(res);
	}

	async addBroadcast(data) {
		const res = await landingPageApiHandler.addBroadcast(data);
		return this.isOk(res);
	}

	async getProposals(languageId) {
		this.setProposals(this.toState(this.proposals.data, true));
		const res = await landingPageApiHandler.getProposals(languageId);
		const result = res?.result ? this.toState(res.result) : { ...this.proposals, isLoading: false };
		this.setProposals(result);
	}

	async proposalsReorder(data) {
		const res = await landingPageApiHandler.proposalsReorder(data);
		return this.isOk(res);
	}

	async deleteProposals(id) {
		const res = await landingPageApiHandler.deleteProposals(id);
		return this.isOk(res);
	}

	async getConfigurations() {
		this.setConfigurations(this.toState(this.configurations.data, true));
		const res = await landingPageApiHandler.getConfigurations();
		const result = res?.result ? this.toState(res.result) : { ...this.configurations, isLoading: false };
		this.setConfigurations(result);
	}

	async getCourses(filter) {
		this.setCourses(this.toState(this.courses.data, true));
		const res = await landingPageApiHandler.getCourses(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.courses, isLoading: false };
		this.setCourses(result);
	}

	async addCourse(data) {
		const res = await landingPageApiHandler.addCourse(data);
		return this.isOk(res);
	}

	async addInstructor(data) {
		const res = await landingPageApiHandler.addInstructor(data);
		return this.isOk(res);
	}

	async addProposals({ languageId, data }) {
		const formData = createAddProposalsFormDataModel(data);
		const res = await landingPageApiHandler.addProposals({ languageId, formData });
		return this.isOk(res);
	}

	async editProposals({ id, data }) {
		const formData = createAddProposalsFormDataModel(data);
		const res = await landingPageApiHandler.editProposals({ id, formData });
		return this.isOk(res);
	}

	async addSectionContent({ sectionId, languageId, data }) {
		const formData = editSectionFormDataModel(data);
		const res = await landingPageApiHandler.addSectionContent({ sectionId, languageId, formData });
		return this.isOk(res);
	}

	async getSectionContent({ sectionId, languageId }) {
		this.setSectionContent(this.toState(this.sectionContent.data, true));
		const res = await landingPageApiHandler.getSectionContent({ sectionId, languageId });
		const result = res?.result.length ? this.toState(res.result[0]) : {
			data: sectionContentInitialState,
			isLoading: false
		};
		this.setSectionContent(result);
	}

	async sendComment(data) {
		const res = await landingPageApiHandler.sendComment(data);
		return this.isOk(res);
	}

	async importImportSubscribers(data) {
		const formData = createImportSubscribersFormDataModel(data);
		const res = await landingPageApiHandler.importImportSubscribers(formData);
		return this.isOk(res);
	}

	async getInstructors(languageId) {
		this.setInstructors(this.toState(this.instructors.data, true));
		const res = await landingPageApiHandler.getInstructors(languageId);
		const result = res?.result ? this.toState(res.result) : { ...this.instructors, isLoading: false };
		this.setInstructors(result);
	}

	async getDynamicPageContent({languageId,path}){
		this.setDynamicPageContent(this.toState(this.dynamicPageContent.data,true));
		const res=await landingPageApiHandler.getDynamicPageContetnt({languageId,path});
		const result=res?.result ? this.toState(res.result) : 
		{data:dynamicPageContentInitialState, isLoading:false};
		this.setDynamicPageContent(result);
	}	
}

const landingPageStore = new LandingPageStore();
export default landingPageStore;