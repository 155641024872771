import StoreBase from '../storeBase';
import { action, makeObservable, observable } from 'mobx';
import languagesApiHandler from 'core/services/apiService/apiHandlers/languagesApiHandler';
import { transformLanguagesTextData } from './helpers/languages';

class LanguagesStore extends StoreBase {
	languages = this.toState({ filteredCount: 0, items: [] });
	predefined = this.toState({ filteredCount: 0, items: [] });
	languagesTexts = this.toState({ filteredCount: 0, items: [] });
	languagesByPage = this.toState({ filteredCount: 0, items: [] });
	languagesForProject = this.toState({});

	constructor() {
		super();
		makeObservable(this, {
			languages: observable,
			predefined: observable,
			languagesTexts: observable,
			languagesByPage: observable,
			languagesForProject: observable,
			setLanguages: action,
			setLanguagesTexts: action,
			setLanguagesByPage: action,
			setLanguagesForProject: action
		});
	}

	setLanguages(languages) {
		this.languages = languages;
	}

	setLanguagesByPage(languagesByPage) {
		this.languagesByPage = languagesByPage;
	}

	setLanguagesTexts(languagesTexts) {
		this.languagesTexts = languagesTexts;
	}

	setPredefinedLanguage(predefined) {
		this.predefined = predefined;
	}

	setLanguagesForProject(languagesForProject) {
		this.languagesForProject = languagesForProject;
	}

	async getLanguages(filter) {
		this.setLanguages(this.toState(this.languages.data, true));
		const res = await languagesApiHandler.getLanguages(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.languages, isLoading: false };
		this.setLanguages(result);
	}

	//this method uses only for languages page
	async getLanguagesByPage(filter) {
		this.setLanguagesByPage(this.toState(this.languagesByPage.data, true));
		const res = await languagesApiHandler.getLanguages(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.languagesByPage, isLoading: false };
		this.setLanguagesByPage(result);
	}

	async addLanguage(data) {
		const res = await languagesApiHandler.addLanguage(data);
		return this.isOk(res);
	}

	async getPredefinedLanguage(filter) {
		this.setPredefinedLanguage(this.toState(this.predefined.data, true));
		const res = await languagesApiHandler.getPredefinedLanguage(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.predefined, isLoading: false };
		this.setPredefinedLanguage(result);
	}

	async deleteLanguage(id) {
		const res = await languagesApiHandler.deleteLanguage(id);
		return this.isOk(res);
	}

	async getLanguagesText(id, filter) {
		this.setLanguagesTexts(this.toState(this.languagesTexts.data, true));
		const res = await languagesApiHandler.getLanguagesText(id, filter);
		const result = res?.result ? this.toState(res.result) : { ...this.languagesTexts, isLoading: false };
		this.setLanguagesTexts(result);
	}

	async getLanguagesForProject(id, filter) {
		this.setLanguagesForProject(this.toState(this.languagesForProject.data, true));
		const res = await languagesApiHandler.getLanguagesText(id, filter);
		const result = res?.result ? this.toState(transformLanguagesTextData(res.result)) : {
			...this.languagesForProject,
			isLoading: false
		};
		this.setLanguagesForProject(result);
	}

	async publishLanguage(id) {
		const res = await languagesApiHandler.publishLanguage(id);
		return this.isOk(res);
	}
}


const languagesStore = new LanguagesStore();
export default languagesStore;
