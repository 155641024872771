import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import authStore from 'stateManagement/stores/authStore';
import { ROUTE_CONSTANTS } from '../router';

/**
 * If the user is authenticated, render the children, otherwise redirect to the login page
 * @returns The children of the component.
 */
const IsAuthLayer = ({ children }) => {
    const { isAuth } = authStore;

    return (
        <>
            { isAuth ? children : <Redirect to={ROUTE_CONSTANTS.LOGIN} /> }
        </>
    );
};

export default observer(IsAuthLayer);

