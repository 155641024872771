export const transformChatMessageForList = (messagesData) => {
	const { data: { items, filteredCount }, ...restData } = messagesData;

	const messages = {};
	items.forEach((item) => {
		messages[item.id] = item;
	});

	return {
		data: {
			messages,
			filteredCount
		},
		...restData
	};
};