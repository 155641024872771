import React, { useEffect, useState } from 'react';
import { Rate as AndRate, Space, Typography } from 'antd';
import ShowElement from '../showElement';

const { Text } = Typography;

/**
 * It's a wrapper for the Ant Design Rate component that allows you to control the value of the rate component
 * @returns A React component
 */
const Rate = ({
				  onChange,
				  size = 14,
				  allowClear = false,
				  value: controlledValue,
				  disabled = true,
				  showRateNumber = true
			  }) => {
	const [ rateValue, setRateValue ] = useState(0);

	useEffect(() => {
		if(controlledValue) {
			setRateValue(controlledValue);
		} else {
			setRateValue(0);
		}

	}, [ controlledValue ]);

	const handleRateChange = (value) => {
		onChange?.(value);
	};

	const rateToFixedValue = +rateValue?.toFixed(1);

	return (
		<Space className="">
			<ShowElement isShow={showRateNumber}>
				<Text type="secondary">{rateToFixedValue}</Text>
			</ShowElement>
			<AndRate
				disabled={disabled}
				allowClear={allowClear}
				value={rateToFixedValue}
				style={{ fontSize: size }}
				onChange={handleRateChange}
			/>
		</Space>
	);
};

export default Rate;