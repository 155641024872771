import React, { useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import Logo from 'view/components/shared/logo';
import useTranslation from 'hooks/useTranslation';
import devaLogoWhite from 'assets/icons/devaLogoWith.svg';
import devaMinLogo from 'assets/icons/devaMainLogo.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { ADMIN_NAVIGATION } from 'core/constants/navigationConstants';
import './index.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

/**
 * It renders a sidebar with a logo, a menu, and a button to toggle the sidebar
 * @returns A sidebar component that is used to navigate through the admin dashboard.
 */

const Sidebar = () => {
	const history = useHistory();
	const location = useLocation();
	const translate = useTranslation();
	const [ collapsed, setCollapsed ] = useState(false);

	const toggleCollapse = () => {
		setCollapsed(!collapsed);
	};

	const selectedPathName = useMemo(() => {
		const path = location.pathname.slice(1);
		const searchValue = path.search('/');
		if(searchValue === -1) {
			return `/${path}`;
		} else {
			return `/${path.slice(0, searchValue)}`;
		}
	}, [ location.pathname ]);

	const handleNavigationChange = e => {
		const { key } = e;
		history.push(key);
	};

	return (
		<>
			<Sider
				theme="left"
				collapsible
				collapsed={collapsed}
				className="main_side_bar"
				onCollapse={toggleCollapse}
			>

				<div className="collapsed_action_container">

					{
						collapsed ? (
							<Logo src={devaMinLogo} width={40}/>
						) : (
							<Logo src={devaLogoWhite} width={180}/>
						)
					}
				</div>

				<Menu
					theme="dark"
					mode="inline"
					onClick={handleNavigationChange}
					className="sidebar_menu_container"
					selectedKeys={[ selectedPathName ]}
				>
					{
						ADMIN_NAVIGATION.map(({ name, to, icon, subMenu, subTo }) => {
							if(subMenu) {

								return (
									<SubMenu
										key={`${subTo}_${name}`}
										icon={icon}
										title={translate(name)}
										className="side_bar_sub_menu"
									>
										{
											subMenu.map(({ to, name, icon }) => {
												return (
													<Menu.Item
														key={to}
														icon={icon}
													>
														{translate(name)}
													</Menu.Item>
												);
											})
										}
									</SubMenu>
								);
							} else {
								return (
									<Menu.Item
										key={to}
										icon={icon}
									>
										{translate(name)}
									</Menu.Item>
								);
							}

						})
					}

					<Menu.Item
					>

					</Menu.Item>
				</Menu>
			</Sider>
		</>
	);
};
export default observer(Sidebar);