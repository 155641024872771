import moment from 'moment';
import { DATE_FORMATS } from '../constants/globalConstants';

/**
 * Given a date and a format, return the date formatted as a string.
 * @param date - The date to format.
 * @param [format] - The format of the date.
 * @returns A function that takes two parameters, date and format.
 */
const formatDate = (date, format = DATE_FORMATS.TABLE_LONG) => {
	return moment(date).utc().format(format);
};

//const formatDate = (date, format = DATE_FORMATS.TABLE_LONG) => {
// 	return moment(date).utc().format(format);
// };

export default formatDate;
