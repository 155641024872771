import { createElement } from 'react';

/**
 * It takes an array of layers and a component, and returns a component that renders the layers in order, with the last
 * layer being the component
 * @param [layers] - An array of components that will be used as guards.
 * @param Component - The component that you want to render.
 * @returns A component that is wrapped in a series of guards.
 */
const defineGuards = (layers = [], Component) => {
    if (!layers.length) {
        return <Component />;
    }
    return (
        <>
            { createElement(layers[0], {}, defineGuards(layers.slice(1), Component)) }
        </>
    );
};

export default defineGuards;
