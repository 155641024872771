import React, {useState} from "react";
import { Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import './index.scss';
import Button from 'view/components/shared/button';
import ShowElement from "../showElement";
import {ROUTE_CONSTANTS} from '../../../../routing/router'
import landingPageStore from 'stateManagement/stores/landingPageStore'

const { Title, Text } = Typography;

/**
 * It returns a div with a title, a text, a link and an icon
 * @returns A React component
 */

const CongratsTicket = ({
							icon,
							title,
							linkText,
							linkHref,
							messageText,
							buttonText,
							actionKey
						}) => {
	const translate = useTranslation();
	const [ isLoading, setIsLoading ] = useState(false);
	const history = useHistory();

	const {magicReviewAction:{data:{errorMessage: errorMessage,isSuccess:isSuccess, responseType: responseType, properties:properties}}}=landingPageStore

	const actionSubmit=(data)=>{        
        setIsLoading(true);
        landingPageStore.submitReviewMagicAction({key:data.actionKey, data:data.isAccepted}).then(
            (resp)=>{
                if(resp){
					setIsLoading(false);
                    if(resp.result.isSuccess){                        
                        history.push(ROUTE_CONSTANTS.EMPTY);
                    }
                }
                else{      
                              
                }
            }
        )
    }

	return (
		<div className="success-message-content">
			<div>
				<div className="icon-content">
					<img src={icon} alt=""/>
				</div>
				<Title level={3}>{translate(title)}</Title>
				<ShowElement isShow={!actionKey}>
				<Text>{translate(messageText)}</Text>
				{
					linkText && (
						<div className="link-content">
							<Link to={linkHref}>{translate(linkText)}</Link>
						</div>
					)
				}
				</ShowElement>
				<ShowElement isShow={actionKey}>
				<Text>{isSuccess?translate(messageText):translate(errorMessage)}</Text>
				</ShowElement>
				
				<div>
					<ShowElement isShow={buttonText}>
						<Button
                            type="primary"
                            text={translate(buttonText)}                    
                            onClick={()=>actionSubmit({actionKey: actionKey})}
                            style={{width:'110px'}}
                            loading={isLoading}
                        />
					</ShowElement>
				</div>
				
			</div>
		</div>
	);
};

export default CongratsTicket;