import { Link } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'routing/router';

/**
 * It returns a link to the translations page, which contains an image with the source of the image passed in as a prop,
 * and a width of 200px
 * @returns A React component
 */

const Logo = ({
				  src,
				  width = '200'
			  }) => {
	return (
		<Link to={ROUTE_CONSTANTS.DASHBOARD}>
			<img src={src} alt="logo" width={width}/>
		</Link>
	);
};

export default Logo;