import React from 'react';

import { Card as AmdCard, Image } from 'antd';
import './index.scss';

/**
 * It's a function that returns a card component with a title, an image, and an extra element
 * @returns A card component with a title, image, and extra.
 */

const Card = ({ src, title, extra, draggable, ...restProps }) => {
	return (
		<AmdCard
			hoverable
			extra={extra}
			draggable={draggable}
			className="and-card-wrapper"
			title={<span className=" cardTitle textEllipsisBlock">{title}</span>}
			{...restProps}
		>
			<div className="img-container">
				<Image
					src={src}
					alt={title}
					preview={{ src }}
				/>
			</div>
		</AmdCard>
	);
};

export default Card;