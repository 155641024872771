import React from 'react';
import { observer } from 'mobx-react-lite';
import useTranslation from 'hooks/useTranslation';
import languagesStore from 'stateManagement/stores/languagesStore';

const Title = ({ title }) => {
	const { languagesForProject: { data } } = languagesStore;
	const translate = useTranslation(data);

	return (
		<span>{translate(title)}</span>
	);
};

export default observer(Title);