import { useEffect, useState } from 'react';
import App from './app';
import { observer } from 'mobx-react-lite';
import { history } from '../routing/history';
import { BrowserRouter } from 'react-router-dom';
import authStore from 'stateManagement/stores/authStore';
import userStore from 'stateManagement/stores/userStore';
import { Loading, WithLanguage } from './components/global';
import languagesStore from 'stateManagement/stores/languagesStore';
import landingPageStore from 'stateManagement/stores/landingPageStore';
import 'antd/dist/antd.css';
import './index.scss';
import './global_class.scss';
import './ant_customized.scss';

const AppWrapper = () => {
	const { meData: { isLoading: meLoading } } = userStore;
	const [ isLoading, seIsLoading ] = useState(true);
	const { isAuth } = authStore;

	useEffect(() => {
		firstRefresh();
	}, [ meLoading ]);

	const firstRefresh = async () => {
		await authStore.init();
		seIsLoading(false);
	};

	useEffect(() => {
		languagesStore.getLanguages();
	}, []);

	useEffect(() => {
		if(isAuth) {
			userStore.getMe().then(r=>{
				if(r===false){
					authStore.logout()
				}else{
					languagesStore.getLanguages();
					landingPageStore.getConfigurations();
				}
			});					
		};
	}, [ isAuth ]);

	return (
		<BrowserRouter history={history}>
			<WithLanguage>
				{isLoading ? <Loading/> : <App/>}
			</WithLanguage>
		</BrowserRouter>
	);
};

export default observer(AppWrapper);