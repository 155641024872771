import { BEARER } from 'core/constants/globalConstants';
import { HEADER_TYPES } from 'core/constants/apiConstants/headerTypes';
import { CONTENT_TYPES } from 'core/constants/apiConstants/contentTypes';

/**
 * @param {string} contentType
 * @param {string} token
 * @returns http headers
 */
const buildHeaders = (contentType, token) => {

	let headers = {};

	if(token) {
		headers = {
			[HEADER_TYPES.AUTHORIZATION]: `${BEARER} ${token}`,
			...headers
		};
	}

	switch (contentType) {
		case CONTENT_TYPES.APPLICATION_JSON:
			headers = {
				[HEADER_TYPES.CONTENT_TYPE]: CONTENT_TYPES.APPLICATION_JSON,
				...headers
			};
			break;
		case CONTENT_TYPES.OCTET_STREAM:
			headers = {
				[HEADER_TYPES.CONTENT_TYPE]: CONTENT_TYPES.OCTET_STREAM,
				...headers
			};
			break;
		default:
			break;
	}

	return headers;
};

export default buildHeaders;
